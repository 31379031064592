import i18n from '@/i18n';

export const caseStatusMap = {
  Draft: { color: 'primary', text: 'Draft', icon: 'mdi-file-outline' },
  Active: { color: 'success', text: 'Active', icon: 'mdi-clipboard-check-outline' },
  'In Review': { color: 'warning', text: 'In Review', icon: 'mdi-progress-alert' },
  Rejected: { color: 'error', text: 'Rejected', icon: 'mdi-sim-off-outline' },
  Closed: { color: 'info', text: 'Closed', icon: 'mdi-close-circle-outline' },
};

export const runStateMap = {
  New: { color: 'primary', text: 'New', icon: 'mdi-moon-new' },
  'In Progress': { color: 'success', text: 'In Progress', icon: 'mdi-progress-alert' },
  Complete: { color: 'warning', text: 'Complete', icon: 'mdi-clipboard-check-outline' },
};

export const milestoneStatusMap = {
  Open: {},
  Closed: {},
};

export const priorities = {
  High: { color: 'green', value: 'High', text: 'High', icon: 'mdi-arrow-up' },
  Medium: { color: 'blue', value: 'Medium', text: 'Medium', icon: 'mdi-checkbox-blank-circle' },
  Low: { color: 'red', value: 'Low', text: 'Low', icon: 'mdi-arrow-down' },
};

export const resultStatusMap = {
  passed: { color: 'success', value: 'passed', text: 'Passed', icon: 'mdi-check-circle-outline' },
  failed: { color: 'error', value: 'failed', text: 'Failed', icon: 'mdi-close-circle-outline' },
  pending: { color: 'warning', value: 'pending', text: 'Pending', icon: 'mdi-progress-alert' },
  inconclusive: { color: 'info', value: 'inconclusive', text: 'Inconclusive', icon: 'mdi-close-circle-outline' },
  skipped: { color: 'grey', value: 'skipped', text: 'Skipped', icon: 'mdi-skip-next' },
};

export const testsTableHeader = [
  { text: 'Name', value: 'name' },
  { text: 'Priority', value: 'priority' },
  { text: 'Status', value: 'status' },
  { text: 'Actions', value: 'actions' },
];

export const tagsHeader = [
  {
    text: i18n.t("name"),
    align: "start",
    sortable: true,
    value: "name",
    class: "elevation-0 rounded-l-lg",
    checked: true,
  },
  {
    text: i18n.t("description"),
    value: "description",
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t("tagPage.type"),
    value: "entityTypes",
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t("tagPage.count"),
    value: "count",
    sortable: true,
    width: 100,
    checked: true,
  },
  {
    text: "",
    value: "uid",
    sortable: false,
    class: "rounded-r-lg",
    width: 60,
    checked: true,
  }
];

export const projectsDefectHeader = [
  {
    text: i18n.t('id'),
    align: 'start',
    sortable: true,
    value: 'id',
    checked: true,
  },
  {
    text: i18n.t('name'),
    align: 'start',
    sortable: true,
    value: 'name',
    checked: true,
  },
  {
    text: i18n.t('integration'),
    value: 'integration',
    align: 'start',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('priority'),
    value: 'priority',
    align: 'start',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('lastUpdate'),
    value: 'updatedAt',
    align: 'start',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('status'),
    value: 'status',
    align: 'start',
    sortable: true,
    checked: true,
  },
  {
    text: '',
    value: 'uid',
    sortable: false,
    class: 'rounded-r-lg',
    width: 130,
    checked: true,
  },
];

export const projectsViewHeader = [
  {
    text: i18n.t('name'),
    align: 'start',
    sortable: true,
    value: 'name',
    class: 'elevation-0 rounded-l-lg',
    checked: true,
  },
  {
    text: i18n.t('key'),
    align: 'start',
    sortable: true,
    value: 'key',
    class: 'elevation-0 rounded-l-lg',
  },
  {
    text: i18n.t('testRuns'),
    value: 'test',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('testCases'),
    value: 'cases',
    sortable: true,
  },
  {
    text: i18n.t('defects'),
    value: 'defects',
    sortable: true,
  },
  {
    text: i18n.t('projectAdmin'),
    value: 'projadmin',
    sortable: true,
  },
  {
    text: i18n.t('lastChanges'),
    value: 'lastchanges',
    sortable: true,
  },
  {
    text: i18n.t('creationDate'),
    value: 'creationdate',
    sortable: true,
  },
  {
    text: i18n.t('users'),
    value: 'users',
    sortable: true,
    class: 'rounded-r-lg',
    checked: true,
  },
];

export const projectEditHeader = [
  {
    text: i18n.t('user'),
    align: 'start',
    sortable: false,
    value: 'name',
    class: 'rounded-l-lg gray-ish--text',
    checked: true,
  },
  {
    text: i18n.t('role'),
    value: 'role',
    sortable: true,
    class: 'gray-ish--text',
    checked: true,
  },
  {
    text: i18n.t('lastActivity'),
    value: 'lastActivity',
    sortable: true,
    class: 'gray-ish--text',
    checked: true,
  },
  {
    text: i18n.t('projectsName'),
    value: 'project',
    sortable: true,
    class: 'gray-ish--text',
    checked: true,
  },
  {
    text: i18n.t('tag'),
    value: 'tag',
    sortable: true,
    class: 'gray-ish--text',
    checked: true,
  },
  {
    text: i18n.t('email'),
    value: 'email',
    sortable: true,
    class: 'rounded-r-lg gray-ish--text',
    checked: true,
  },
];

export const usersViewHeader = [
  {
    text: i18n.t('name'),
    align: 'start',
    sortable: true,
    value: 'name',
    class: 'elevation-0 rounded-l-lg',
    checked: true,
  },
  {
    text: i18n.t('role'),
    value: 'role',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('tags'),
    value: 'tag',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('email'),
    value: 'email',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('lastActivity'),
    value: 'lastActivity',
    sortable: true,
    checked: true,
  },
  // {
  //   text: this.$t('projectsName'),
  //   value: 'project',
  //   sortable: true,
  //   class: 'rounded-r-lg',
  //   checked: true,
  // },
  {
    text: '',
    value: 'deleteIcon',
    sortable: false,
    class: 'rounded-r-lg',
    checked: true,
  },
];

export const roleMemberHeader = [
  {
    text: i18n.t('user'),
    align: 'start',
    sortable: true,
    value: 'user',
    class: 'elevation-0 rounded-l-lg',
    checked: true,
  },
  {
    text: i18n.t('role'),
    value: 'role',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('project'),
    value: 'project',
    sortable: true,
    checked: true
  },
  {
    text: i18n.t('tags'),
    value: 'tags',
    sortable: true,
    checked: true,
  },
  {
    text: i18n.t('email'),
    value: 'email',
    sortable: true,
    checked: true,
  },
]

export const customTemplatesHeader = [
  {
    text: i18n.t('name'),
    align: 'start',
    sortable: true,
    value: 'name',
    class: 'elevation-0 rounded-l-lg',
    checked: true,
    width: '50%',
  },
  {
    text: i18n.t('creationDate'),
    value: 'createdAt',
    sortable: true,
    checked: true,
    width: '20%'
  },
  {
    text: i18n.t('templatesPage.creator'),
    value: 'createdBy',
    sortable: true,
    checked: true,
    width: '20%'
  },
  {
    text: '',
    value: 'uid',
    sortable: false,
    checked: true,
    class: 'rounded-r-lg',
    width: '10%',
  },
];

export const customFieldsHeader = [
  {
    text: i18n.t('name'),
    align: 'start',
    sortable: true,
    value: 'name',
    class: 'elevation-0 rounded-l-lg',
    checked: true,
    width: '45%'
  },
  {
    text: i18n.t('customFieldPage.dataType'),
    value: 'type',
    sortable: true,
    checked: true,
    width: '15%'
  },
  {
    text: i18n.t('creationDate'),
    value: 'createdAt',
    sortable: true,
    checked: true,
    width: '15%'
  },
  {
    text: i18n.t('customFieldPage.dataSource'),
    value: 'source',
    sortable: true,
    checked: true,
    width: '15%'
  },
  {
    text: 'actions',
    value: 'uid',
    sortable: false,
    class: 'rounded-r-lg hidden no-table-label',
    width: '10%',
  },
];

export const TestCaseTableHeader = [
  { text: 'ID', value: 'id', sortable: true, checked: true, isSelected: true, width: "15%" },
  { text: 'Title', value: 'name', sortable: true, checked: true, isSelected: true, width: "50%" },
  { text: 'Priority', value: 'priority', sortable: true, checked: true, isSelected: true, width: "15%" },
  { text: 'Tags', value: 'tags', sortable: true, checked: true, width: "15%" },
  { text: '', value: 'actions', sortable: false, checked: true, width: "5%" },
];

export const executionTableHeader = [
  { text: 'ID', value: 'id', sortable: true, checked: true, isSelected: true, width: "15%" },
  { text: 'Name', value: 'name', sortable: true, checked: true, isSelected: true, width: "35%" },
  { text: 'Assigned to', value: 'assignees', sortable: true, checked: true, isSelected: true, width: '15%' },
  { text: 'Priority', value: 'priority', sortable: true, isSelected: true, checked: true, width: '15%' },
  { text: 'Status', value: 'status', sortable: true, isSelected: true, checked: true, width: '15%' },
  { text: 'Actions', value: 'actions', sortable: false, checked: true, width: '5%' },
];

export const workspaceTableHeader = [
  { text: 'ID', value: 'id', sortable: true, checked: true, isSelected: true },
  { text: 'Name', value: 'name', sortable: true, checked: true, isSelected: true },
  { text: 'Priority', value: 'priority', sortable: true, checked: true, isSelected: true },
  { text: 'Status', value: 'status', sortable: true, checked: true, isSelected: true },
  { text: 'Assign date', value: 'assignDate', sortable: true, checked: true, isSelected: true },
  // { text: 'Due date', value: 'Duedate', sortable: true, checked: true, isSelected: true },
  { text: 'Project', value: 'project', sortable: true, checked: true, isSelected: true },
  { text: 'Tags', value: 'tags', sortable: true, checked: true, isSelected: true },
  { text: 'actions', value: 'actions', sortable: true, checked: true },
]

export const caseInRunTableHeader = [
  { text: 'ID', value: 'id', sortable: true, isSelected: true, checked: true },
  { text: 'Name', value: 'name', sortable: true, isSelected: true, checked: true },
  { text: 'Assigned to', value: 'assigned', sortable: true, isSelected: true, checked: true },
  { text: 'Priority', value: 'priority', sortable: true, isSelected: true, checked: true },
  { text: 'Status', value: 'status', sortable: true, isSelected: true, checked: true },
  { text: '', value: 'actions', sortable: false, isSelected: true, checked: true },
];

export const TestRunTableHeader = [
  { id: 1, text: 'Name', value: 'name', isSelected: true, checked: true, width: '25%'},
  { id: 2, text: 'Priority', value: 'priority', isSelected: true, checked: true, width: '10%' },
  { id: 3, text: 'Status', value: 'status', isSelected: true, checked: true, width: '10%' },
  { id: 4, text: 'Test cases', value: 'testcases', isSelected: true, checked: true, width: '10%' },
  { id: 5, text: 'Milestone', value: 'milestone', isSelected: true, checked: true, width: '10%' },
  { id: 6, text: 'Creation date', value: 'creationdate', isSelected: true, checked: true, width: '10%' },
  { id: 7, text: 'Tags', value: 'tags', isSelected: true, checked: true, width: '10%' },
  { id: 8, text: 'Progress', value: 'progress', isSelected: true, checked: true, width: '10%' },
  { id: 9, text: 'Actions', value: 'actions', isSelected: true, checked: true, sortable: false, width: '5%' },
];

export const testConfigurationOptions = [
  { text: 'Chrome', value: 'chrome' },
  { text: 'Firefox', value: 'firefox' },
  { text: 'Safari', value: 'safari' },
  { text: 'Edge', value: 'edge' },
  { text: 'IE', value: 'ie' },
  { text: 'Android', value: 'android' },
  { text: 'iOS', value: 'ios' },
  { text: 'Linux', value: 'linux' },
  { text: 'MacOS', value: 'macos' },
  { text: 'Windows', value: 'windows' },
  { text: 'Headless', value: 'headless' },
  { text: 'Mobile', value: 'mobile' },
  { text: 'Desktop', value: 'desktop' },
  { text: 'Tablet', value: 'tablet' },
  { text: 'Landscape', value: 'landscape' },
  { text: 'Portrait', value: 'portrait' },
  { text: 'Dark Mode', value: 'darkmode' },
  { text: 'Light Mode', value: 'lightmode' },
  { text: 'High Contrast', value: 'highcontrast' },
  { text: 'Low Contrast', value: 'lowcontrast' },
  { text: 'No JavaScript', value: 'nojs' },
  { text: 'No CSS', value: 'nocs' },
  { text: 'No Images', value: 'noimages' },
  { text: 'No Audio', value: 'noaudio' },
  { text: 'No Video', value: 'novideo' },
  { text: 'No Cookies', value: 'nocookies' },
  { text: 'No Flash', value: 'noflash' },
  { text: 'No Popups', value: 'nopopups' },
  { text: 'No Referrer', value: 'noreferrer' },
  { text: 'No Cache', value: 'nocache' },
  { text: 'No Storage', value: 'nostorage' },
  { text: 'No IndexedDB', value: 'noindexeddb' },
  { text: 'No Service Worker', value: 'noserviceworker' },
  { text: 'No GPU', value: 'nogpu' },
  { text: 'No WebGL', value: 'nowebgl' },
  { text: 'No WebRTC', value: 'nowebrtc' },
  { text: 'No WebSockets', value: 'nowebsockets' },
  { text: 'No Geolocation', value: 'nogeolocation' },
  { text: 'No Microphone', value: 'nomicrophone' },
  { text: 'No Camera', value: 'nocamera' },
  { text: 'No MIDI', value: 'nomidi' },
  { text: 'No Notifications', value: 'nonotifications' },
  { text: 'No Battery', value: 'nobattery' },
];

export const MilestoneTableHeader = [
  { text: 'Name', value: 'name', isSelected: true, checked: true, width: '35%' },
  { text: 'Status', value: 'status', isSelected: true, checked: true, width: '10%' },
  { text: 'Test runs', value: 'testruns', isSelected: true, checked: true, width: '10%' },
  { text: 'Test cases', value: 'testcases', isSelected: true, checked: true, width: '10%' },
  { text: 'Start date', value: 'startdate', isSelected: true, checked: true, width: '10%' },
  { text: 'Due date', value: 'due_at', isSelected: true, checked: true, width: '10%' },
  { text: 'Progress', value: 'progress', isSelected: true, checked: true, width: '10%' },
  { text: 'Actions', value: 'actions', isSelected: true, checked: true, sortable: false, width: '5%' },
];
export const MilestoneTestRunTableHeader = [
  { text: 'Name', value: 'name', isSelected: true, checked: true },
  { text: 'Priority', value: 'priority', isSelected: true, checked: true },
  { text: 'Test Cases', value: 'testcases', isSelected: true, checked: true },
  { text: 'Creation Date', value: 'creationdate', isSelected: true, checked: true },
  { text: 'Tags', value: 'tags', isSelected: true, checked: true },
  { text: 'Progress', value: 'progress', isSelected: true, checked: true },
];

export const MilestoneTestPlanTableHeader = [
  { text: i18n.t('name'), value: 'name', isSelected: true, checked: true, width: '25%' },
  { text: i18n.t('priority'), value: 'priority', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('status'), value: 'status', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('configurations'), value: 'configurations', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('plans.list.testRuns'), value: 'testruns', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('creationDate'), value: 'creationdate', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('tags'), value: 'tags', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('progress'), value: 'progress', isSelected: true, checked: true, width: '10%' },
  { text: '', value: 'actions', isSelected: true, checked: true, sortable: false, width: '5%' },
];

export const SharedStepTableHeader = [
  { text: 'Title', value: 'name', isSelected: true, checked: true, width: '50%' },
  { text: 'Number of steps', value: 'steps_number', isSelected: true, checked: true, width: '20%' },
  { text: 'Referenced by ', value: 'referenced_by', isSelected: true, checked: true, width: '20%' },
  { text: '', value: 'actions', isSelected: true, sortable: false, width: '10%' },
];

export const DefaultFilter = {
  selectedRoles: [],
  testRuns: [0, 100],
  testCases: [0, 100],
  users: [0, 100],
  dateRange: {
    start: '',
    end: '',
  },
  dueDateRange: {
    start: '',
    end: '',
  },
  progress: [0, 100],
};
export const TestPlansTestRunsTableHeaderAll = [
  { text: i18n.t('name'), value: 'name', isSelected: true, checked: true, width: '50%' },
  { text: i18n.t('priority'), value: 'priority', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('plans.create.testRuns.tableHeader.testCases'), value: 'testcases', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('creationDate'), value: 'creationdate', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('tag'), value: 'tag', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('progress'), value: 'progress', isSelected: true, checked: true, width: '10%' },
];
export const TestPlansTestRunsTableHeaderSelected = [
  { text: i18n.t('name'), value: 'name', isSelected: true },
  { text: i18n.t('priority'), value: 'priority', isSelected: true },
  { text: i18n.t('plans.create.testRuns.tableHeader.testCases'), value: 'testcases', isSelected: true },
  { text: i18n.t('creationDate'), value: 'creationdate', isSelected: true },
  { text: i18n.t('tag'), value: 'tag', isSelected: true },
  { text: i18n.t('progress'), value: 'progress', isSelected: true },
  { text: i18n.t('configurations'), value: 'configuration', isSelected: true },
];

export const TestPlansTableHeader = [
  { text: i18n.t('name'), value: 'name', isSelected: true, checked: true, width: '25%' },
  { text: i18n.t('priority'), value: 'priority', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('status'), value: 'status', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('configurations'), value: 'configurations', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('plans.list.testRuns'), value: 'testruns', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('creationDate'), value: 'creationdate', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('tags'), value: 'tags', isSelected: true, checked: true, width: '10%' },
  { text: i18n.t('progress'), value: 'progress', isSelected: true, checked: true, width: '10%' },
  { text: '', value: 'actions', isSelected: true, checked: true, sortable: false, width: '5%' },
];
