<template>
    <div class="d-flex justify-center align-center flex-column">
        <img :src="imageSrc" alt="Empty state image" class="placeholder-img mb-6" />
        <div>
            <div class="d-flex flex-column align-center">
                <p class="ma-0 font-weight-bold fs-24px">
                    {{ title }}
                </p>
                <slot name="description"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
};
</script>