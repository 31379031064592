<template>
  <v-container>
    <v-row>
      <v-col>
        <!--  Dropdown with Projects List -->
        <v-select
          v-model="selectedProject"
          :items="projects"
          attach
          prepend-icon="mdi-briefcase"
          item-text="name"
          :no-data-text="$t('noProjects')"
          return-object
          outlined
          dense
          hide-details
          :menu-props="{ bottom: true, offsetY: true }"
          :placeholder="$t('select')"
          @click:append-outer="toggleDropdown"
        >
          <!-- Scoped Slot for items -->
          <template v-slot:item="data">
            <v-list-item-content class="text-left">
              <v-list-item-title v-text="data.item.name" />
            </v-list-item-content>
          </template>

          <!-- Scoped Slot for 'Add Project' button -->
          <template v-slot:append-item>
            <v-divider class="mt-2" />
            <v-list-item @click="addNewProject">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('new') }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    account: { type: Object, required: true }
  },
  data() {
    return {
      dropdown: false,
    };
  },
  mounted() {
    this.getProjects(this.account.handle)
  },
  computed: {
    ...mapGetters({ projects: "project/get" }),
    selectedProject: {
      get() {
        return this.projects.find((project) => project.key === this.$route.params.key);
      },
      set(value) {
        this.$router.go({ params: { key: value } });
      }
    },
  },
  methods: {
    ...mapActions({ getProjects: "project/get" }),
    toggleDropdown() {
      this.dropdown = !this.dropdown;
    },
    addNewProject() {
      this.$emit('add-project');
    },
  },
};
</script>
