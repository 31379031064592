<template>
  <div>
    <v-btn
      elevation="0"
      class="text-capitalize ml-2 px-3 rounded-lg"
      height="40"
      width="112"
      @click="showDialog = true"
    >
      <span class="px-4"> {{ $t('filters') }} <v-icon
        class="ml-1"
        size="16px"
      >mdi-filter-variant</v-icon></span>
    </v-btn>
    <v-dialog
      v-model="showDialog"
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex justify-between pt-8 pb-6">
            <p class="ma-0 font-weight-bold text-h6 text-sm-h5 text-start black--text">
              {{ $t('filters') }}
            </p>
            <v-icon
              class="pointer"
              @click="showDialog = false"
            >
              mdi-close
            </v-icon>
          </div>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('configurations') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in configurations"
                  :key="index"
                  v-model="panel_configuration"
                  off-icon="mdi-checkbox-blank"
                  :value="item"
                  dense
                  :label="item"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('testruns.create_testrun.priority') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in priorities"
                  :key="index"
                  v-model="panel_priority"
                  off-icon="mdi-checkbox-blank"
                  :value="item"
                  dense
                  :label="item"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('testruns.create_testrun.status') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in statuses"
                  :key="index"
                  v-model="panel_status"
                  off-icon="mdi-checkbox-blank"
                  :value="item"
                  dense
                  :label="item"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div>
            <div class="mb-1 text-start mt-4">
              {{ $t('numberOfTestRuns') }}
            </div>
            <v-range-slider
              v-model="testruns"
              style="height: 50px;"
              color="blue"
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testruns[0]"
                dense
                single-line
                filled
                :value="testruns[0]"
                color="blue"
                class="mr-0 custom_input rounded-lg"
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testruns[1]"
                dense
                single-line
                filled
                :value="testruns[1]"
                color="blue"
                class="mr-0 custom_input rounded-lg"
              />
            </div>
          </div>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('testruns.create_testrun.milestone') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  dense
                  single-line
                  filled
                  color="blue"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Search"
                  class="custom_input mx-0 rounded-lg"
                />
                <v-checkbox
                  v-for="(item) in milestones"
                  :key="item.uid"
                  v-model="panel_milestone"
                  off-icon="mdi-checkbox-blank"
                  :value="item"
                  dense
                  :label="item.name"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-start pt-6">
            <div class="mt-4">
              {{ $t('creationDate') }}
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="isStartDatePickerOpen"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    single-line
                    filled
                    color="blue"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    class="mr-0 custom_input rounded-lg"
                    :value="startDate"
                    clearable
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  @change="isStartDatePickerOpen = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                {{ $t('-') }}
              </div>
              <v-menu
                v-model="isEndDatePickerOpen"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    single-line
                    filled
                    color="blue"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                    class="custom_input rounded-lg"
                    :value="endDate"
                    clearable
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="endDate"
                  @change="isStartDatePickerOpen = false"
                />
              </v-menu>
            </div>
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  {{ $t('testruns.create_testrun.tag') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="searchTag"
                    dense
                    single-line
                    filled
                    color="blue"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Search"
                    class="custom_input mx-0 rounded-lg"
                  />
                  <v-checkbox
                    v-for="(item) in filterTags"
                    :key="item.uid"
                    v-model="panel_tag"
                    off-icon="mdi-checkbox-blank"
                    :value="item"
                    dense
                    :label="item.name"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div>
              <div class="mb-1 text-start mt-4">
                {{ $t('testruns.progress') }}
              </div>
              <v-range-slider
                v-model="progress"
                style="height: 50px;"
                color="blue"
              />
              <div class="d-flex align-center">
                <v-text-field
                  v-model="progress[0]"
                  dense
                  single-line
                  filled
                  :value="progress[0]"
                  color="blue"
                  class="mr-0 custom_input rounded-lg"
                />
                <div class="mx-4 font-weight-bold text-h6">
                  -
                </div>
                <v-text-field
                  v-model="progress[1]"
                  dense
                  single-line
                  filled
                  :value="progress[1]"
                  color="blue"
                  class="mr-0 custom_input rounded-lg"
                />
              </div>
            </div>
            <div class="d-flex justify-end mt-5">
              <v-btn
                width="10rem"
                color="gray-100"
                class="mr-4 text-capitalize"
                elevation="0"
                @click="cancel"
              >
                {{ $t('clearAll') }}
              </v-btn>
              <v-btn
                width="10rem"
                color="blue"
                dark
                elevation="0"
                class="text-capitalize"
                @click="apply"
              >
                {{ $t('apply') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import makeMilestonesService from '@/services/api/milestone';
import makeTagsService from '@/services/api/tag';

let milestoneService;
let tagService;
export default {
  name: "PlanFilter",
  data() {
    return {
      priorities: ["High", "Medium", "Low"],
      configurations: ["Browsers", "Operating Systems"],
      statuses:["Active","In progress", "Passed", "Failed", "Rerun", "New"],
      milestones: [],
      tags: [],
      searchTag: null,
      panel_priority: [],
      panel_configuration: [],
      panel_status: [],
      panel_milestone: [],
      panel_tag: [],
      testrun: [0, 100],
      testruns: [0, 100],
      progress: [0, 100],
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,
      showDialog: false,
    };
  },
  computed:{
    filterTags(){
      if(!this.searchTag)
        return this.tags
      
      return this.tags?.filter(tag => tag.startsWith(this.searchTag))
    }
  },

  created() {
    milestoneService = makeMilestonesService(this.$api);
    tagService = makeTagsService(this.$api);
  },

  async mounted() {
    await this.getAllMileStones();
    await this.getAllTags();
  },

  methods: {
    apply() {
      this.$emit("applyFilters", {
        panel_priority: this.panel_priority,
        panel_status: this.panel_status,
        panel_milestone: this.panel_milestone,
        panel_configurations: this.panel_configuration,
        panel_tag: this.panel_tag,
        testruns_range: this.testruns,
        progress_range: this.progress,
        dateRange: {
          start: this.startDate,
          end: this.endDate,
        },
      });
      this.showDialog = false;
    },
    cancel() {
      this.panel_priority = [];
      this.panel_status = [];
      this.panel_milestone = [];
      this.panel_configurations = [];
      this.panel_tag = [];
      this.testrun = [0, 100];
      this.testruns = [0, 100];
      this.progress = [0, 100];
      this.startDate = new Date().toISOString().substr(0, 10);
      this.endDate = new Date().toISOString().substr(0, 10);
      this.apply()
    },
    async getAllMileStones(){
      try{
        const response = await milestoneService.getMilestones(
          this.$route.params.handle,
          this.$route.params.key
        );
        if(response.status === 200) {
          this.milestones = response.data.milestones;
        }
      } catch(err){
        console.log(err)
      }
    },
    async getAllTags(){
      try{
        const response = await tagService.getTags(
          this.$route.params.handle,
          this.$route.params.key
        );
        if(response.status === 200) {
          this.tags = response.data;
        }
      } catch(err){
        console.log(err)
      }
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
