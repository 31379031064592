<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6 table-fixed data-table-style"
    :server-items-length="totalTemplates"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    @update:options="updatePaginationOptions"
    @click:row="handleClick"
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="item.name.length < 61"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="custom-attribute text-truncate font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            {{ item.name }}
          </div>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      <span>{{ formatCreatedAt(item.createdAt) }}</span>
    </template>

    <template v-slot:[`item.createdBy`]="{ item }">
      <span>{{ item.creator.firstName }} {{ item.creator.lastName }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <div class="d-flex justify-end">
        <v-btn
          icon
          color="primary"
          @click.stop="$emit('edit', item)"
        >
          <EditIcon />
        </v-btn>

        <v-btn
          icon
          color="primary"
          @click.stop="$emit('delete', item)"
        >
          <DeleteIcon />
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate } from '@/utils/util';
import { defineComponent } from 'vue';

// Change the import statements for the icons
import EditIcon from '@/assets/svg/edit.svg?component';
import DeleteIcon from '@/assets/svg/delete.svg?component';

import { dataTypes } from '@/constants/customField.js';

export default defineComponent({
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
    totalTemplates: Number
  },

  components: {
    EditIcon,
    DeleteIcon
  },



  methods: {
    getTypeLabel(type)
    {
      return dataTypes.find(item => item.type === type)?.name || ''
    },

    formatCreatedAt(createdAt)
    {
      return formatDate(createdAt, 'MM/dd/yy')
    },
    updatePaginationOptions(options)
    {
      this.$emit('update-pagination', options)
    },
    handleClick(row) {
      this.$emit('viewDetail', row);
    },
  }
})
</script>
