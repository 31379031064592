<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="table-fixed data-table-style mt-6"
    :headers="filteredHeaders"
    :items="itemsPerView"
    :item-key="itemKey"
  >

    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <div class="">
          <v-checkbox
            v-model="item.toBeSelected"
            :value="item.toBeSelected"
            class="field-theme"
            hide-details
            dense
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            @change="toBeSelectedHandler"
          >
            <template v-slot:label>
              <div class="text-subtitle-2 font-weight-bold text-truncate">
                {{ item.name }}
              </div>
            </template>
          </v-checkbox>
        </div>
      </td>
    </template>
    <template />
    <template v-slot:[`item.testcases`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-3">
            {{ item.testcases ?? 0 }} <span class="text-lowercase">{{ $t('testCases') }}</span>
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.testruns`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.testruns ?? 0 }} <span class="text-lowercase">{{ $t('testRuns') }}</span>
          </div>
        </div>
      </td>
    </template>

    <template v-slot:[`item.tags`]="{ item }">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="Array.isArray(item.customFields?.tags) && item.customFields.tags.length < 3"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="custom-attribute font-weight-regular text-theme-table-text"
            v-bind="attrs"
            v-on="on"
          >
            <template>
              <div class="text-truncate">
                {{ Array.isArray(item.customFields?.tags) && item.customFields.tags.length > 0 ? item.customFields.tags.join(', ') : $t('no_tags')}}              
              </div>
            </template>
          </span>
        </template>
        <span>
          {{ Array.isArray(item.customFields?.tags) ? item.customFields.tags.join(', ') : '' }}
        </span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.creationdate`]="{ item }">
      <span>{{ formatCreatedAt(item.createdAt) }}</span>
    </template>
    <template v-slot:[`item.progress`]="{ item }">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-progress-linear
          rounded
          :color="item.progress > 70 ? '#F2284E' : '#0c2ff3'"
          background-color="#F2F4F7"
          :height="8"
          :value="item.progress"
        />
        <span class="font-weight-regular ml-3">{{ item.progress ?? 0 }}%</span>
      </div>
    </template>
  </v-data-table>
</template>
  
<script>
import { formatDate } from '@/utils/util';
export default {
    props: {
        tableFilter: {
            type: String,
            default: 'all',
        },
        runsData: {
            type: Array,
        },
        filteredHeaders: {
            type: Array,
        },
        filteredItems: {
            type:Array,
        },
        rowClass: {
            type:Function,
        }
    },
    data() {
        return {
            runs: this.runsData,
            itemKey: 'uid',
            isAllUserChecked: false,
            isSelectedUserChecked: false,
        };
    },
    computed: {
        itemsPerView() {
            if (this.tableFilter === 'all') {
                return this.filteredItems.filter((item) => !item.selected);
            } else {
                return this.filteredItems.filter((item) => item.selected);
            }
        },
    },
    watch: {
        tableFilter(newValue) {
            this.toggleUsers();
        },
    },
    methods: {
        toggleUsers() {
            const isSelected = this.tableFilter === 'all' ? this.isAllUserChecked : this.isSelectedUserChecked;
            this.runs.forEach((user) => {
                const condition = this.tableFilter === 'all' ? !user.selected : user.selected;
                if (condition) {
                    this.$set(user, 'toBeSelected', isSelected);
                }
            });
        },
        toBeSelectedHandler() {
            const filteredUsers =
                this.tableFilter === 'all'
                    ? this.runs.filter((user) => !user.selected)
                    : this.runs.filter((user) => user.selected);
            const allTrue = filteredUsers.every((user) => user.toBeSelected);

            if (this.tableFilter === 'all') {
                this.isAllUserChecked = allTrue;
            } else {
                this.isSelectedUserChecked = allTrue;
            }
        },
        formatCreatedAt(createdAt)
        {
          return formatDate(createdAt, 'MM/dd/yy')
        },
    },
};
</script>
  