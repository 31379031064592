<template>
  <div>
    <v-dialog
      v-model="isOpenStatus"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="clickOutside"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">
              {{ $t('edit') }}
            </h2>
            <v-btn
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div>
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('testFolder') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedFolder"
              :placeholder="$t('chooseFolder')"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              :items="folders"
              item-text="name"
              item-value="uid"
              class="rounded-lg field-theme custom-prepend"
              dense
              height="38"
            />
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('priority') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedPriority"
              :placeholder="$t('choosePriority')"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              :items="priorities"
              item-text="text"
              item-value="value"
              class="rounded-lg field-theme custom-prepend"
              dense
              height="38"
            />
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('addTags') }}
              </v-label>
            </div>
            <TagSelector
              v-model="tagsData.selectedTags"
              :items="tags"
              class="pt-1 mt-0 rounded-lg field-theme custom-prepend"
            />
            <section class="d-flex flex-column w-full">
              <div class="d-flex w-full justify-space-between align-center">
                <p class="font-weight-medium ma-0">
                  {{ $t('replaceExistingTags') }}
                </p>
                <v-switch
                  v-model="showReplaceTag"
                  inset
                />
              </div>

              <section
                v-if="showReplaceTag"
                class="d-flex w-full flex-column"
              >
                <div
                  class="d-flex flex-column"
                >
                  <div class="select-title mt-4 mb-1">
                    <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                      {{ $t('replaceTag') }}
                    </v-label>
                  </div>
                  <TagSelector
                    v-model="tagsData.replaceTag"
                    :items="tags"
                    class="pt-1 mt-0 rounded-lg field-theme custom-prepend"
                  />
                </div>
                <div
                  class="d-flex flex-column"
                >
                  <div class="select-title mb-1">
                    <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                      {{ $t('with') }}
                    </v-label>
                  </div>
                  <TagSelector
                    v-model="tagsData.withTag"
                    :items="tags"
                    class="pt-1 mt-0 rounded-lg field-theme custom-prepend"
                  />
                </div>
              </section>
            </section>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          depressed
          class="text-capitalize btn-theme"
          elevation="0"
          @click="$emit('closeDialog')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme text-capitalize"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="$emit('clickSave', selectedFolder, selectedPriority, tagsData)"
        >
          {{ $t('save') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import makeTagService from '@/services/api/tag';
import makeFoldersService from '@/services/api/folder';
import { showErrorToast } from '@/utils/toast';
import TagSelector from '@/components/base/TagSelector.vue';



export default {
  name: 'EditDialog',
  components: {
    TagSelector,
  },
  props: {
    isOpen: Boolean,
    priorities: Array,
    items: Array,
  },
  data() {
    return {
      tagsData: {
        selectedTags: [],
        replaceTag: [],
        withTag: [],
      },
      showReplaceTag: false,
      selectedPriority: "",
      selectedFolder: "",
      isOpenStatus: this.isOpen,
      tags: [],
      folders: [],
    };
  },

  watch: {
    async isOpen(newVal) {
      this.isOpenStatus = newVal;
      if (!newVal) {
        this.resetForm();
      } else {
           this.fetchFolders(); 
      }
    }
  },
  async created() {
    await this.fetchTags();
    await this.fetchFolders(); 
    this.selectedFolder = this.$store.state.folder.selectedFolderUID 
  },
  methods: {
    clickOutside() {
      this.$emit("closeDialog");
    },
    async fetchTags() {
      const tagService = makeTagService(this.$api);
      try {
        const response = await tagService.getTags(this.$route.params.handle);
        if (response.status === 200) {
          this.tags = response.data.map((tag) => ({ uid: tag.uid, name: tag.name }));
        } else {
          showErrorToast(this.$swal, 'fetchError', { item: 'Tags' });
        }
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'Tags' });
      }
    },
    async fetchFolders() {
        const folderService = makeFoldersService(this.$api);
      try {
        const response = await folderService.getProjectFolders(this.$route.params.handle, this.$route.params.key);
        if (response.status === 200) {
                const {
          data: { folders },
        } = await folderService.getProjectFolders(this.$route.params.handle, this.$route.params.key);
          this.folders = this.findLeafNodes(folders);
        } else {
          showErrorToast(this.$swal, 'fetchError', { item: 'Folders' });
        }
      } catch (error) {
        showErrorToast(this.$swal, 'fetchError', { item: 'Folders' });
      }
    },
    findLeafNodes(folders) {
      let nodes = [];
      for (let idx = 0; idx < folders.length; idx++) {
        let parent = {
          name: folders[idx].name,
          uid: folders[idx].uid,
        };
        if (!folders[idx].children || folders[idx].children.length < 1) {
          nodes.push(parent);
        } else {
          nodes.push(parent, ...this.findLeafNodes(folders[idx].children));
        }
      }
      return nodes;
    },
    resetForm() {
      this.tagsData = {
        selectedTags: [],
        replaceTag: [],
        withTag: [],
      };
      this.showReplaceTag = false;
      this.selectedPriority = "";
      this.selectedFolder = "";
    },
  },
};
</script>

<style>
.select-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
input {
  height: 38px !important;
}

</style>
