<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    :value="selectedItems"
    height="100%"
    show-select
    @input="onRowClick"
    @click:row="handleRowClick"
  >
    <template v-slot:[`header.actions`]="{header}">
      <div class="d-none">
        {{ header.text }}
      </div>
    </template>

    <template v-slot:[`item.name`]="{ item }">
      <div class="font-weight-bold">
        {{ item.name }}
      </div>
    </template>

    <template v-slot:[`item.users`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        <template v-if="item?.members?.length > 0">
          <div class="avatar-group">
            <v-avatar
              v-for="(item, index) in item?.members?.slice(0, 5)"
              :key="index"
              class="mr-n2 img-custom-border"
              size="36"
            >
              <img
                :src="resolveAvatarUrl(item?.avatar_url)"
                width="100%"
                alt="logo"
                class="pa-n1"
              >
            </v-avatar>
            <!-- Show the remaining count if there are more than 5 members -->
            <v-avatar
              v-if="item.members.length > 5"
              class="mr-n2 img-custom-border img-rest-amount"
              size="36"
            >
              <span class="font-weight-bold fs-14px">+{{ item.members.length - 5 }}</span>
            </v-avatar>
          </div>
        </template>
      </span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <div class="d-flex justify-space-between">
        <v-tooltip
          top
          :disabled="isAbleToManage"
        >
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              color="primary"
              :class="{ 'disabled-action': !isAbleToManage }"
              @click="handleClickEdit(item, $event)"
              v-on="{ ...tooltip }"
            >
              <EditIcon />
            </v-btn>
          </template>
          <span>{{ $t('rolePage.noPermissionToDo', { action: $t('edit').toLowerCase() }) }}</span>
        </v-tooltip>

        <v-tooltip
          top
          :disabled="isAbleToManage"
        >
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              color="primary"
              :class="{ 'disabled-action': !isAbleToManage }"
              @click="handleClickDelete(item, $event)"
              v-on="{ ...tooltip }"
            >
              <DeleteIcon />
            </v-btn>
          </template>
          <span>{{ $t('rolePage.noPermissionToDo', { action: $t('delete').toLowerCase() }) }}</span>
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>
  
  <script>
  import { createNamespacedHelpers  } from 'vuex';
  
  import EditIcon from '@/assets/svg/edit.svg';
  import DeleteIcon from '@/assets/svg/delete.svg';
  
  const { mapState } = createNamespacedHelpers('user');
  
  export default {
    name: 'RoleTable',
  
    components: {
      EditIcon,
      DeleteIcon,
    },
    props: {
      headers: Array,
      itemKey: String,
      items: Array,
      clearSelection: Boolean,
    },
  
    data() {
      return {
        roles: this.items,
        selectedItems: [],
      }
    },
  
    computed: {
      ...mapState(['currentAccount']),
  
      isAbleToManage() {
        return true;
      }
    },
    watch: {
        clearSelection(newVal) {
        if (newVal) {
            this.selectedItems = [];
            this.$emit('select-item', this.selectedItems);
        }
        },
    },
    methods: {
        resolveAvatarUrl(avatarUrl) {
            return avatarUrl || require('@/assets/png/avatar.png');
        },
        onRowClick(item) {
        this.selectedItems = item;
        this.$emit('select-item', this.selectedItems);
        },
        handleRowClick(item) {
        this.$emit('select-row', item);
        },
        handleClickEdit(item, event) {
            event.stopPropagation(); 
            if (this.isAbleToManage) {
                this.$emit('edit', item);
            }
        },
        handleClickDelete(item, event) {
            event.stopPropagation(); 
            if (this.isAbleToManage) {
                this.$emit('delete', item);
            }
        }
    }
  };
  </script>

<style scoped>
.img-custom-border {
  border: solid 2px #fff;
}
.img-rest-amount {
  background: #EAECF0;
}
</style>