<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-start justify-space-between">
      <div class="d-flex flex-column align-start">
        <v-btn
          class="pa-0"
          text
          color="blue"
          @click="$emit('back-to-roles')"
        >
          <v-icon left>
            mdi-chevron-left
          </v-icon>
          Roles
        </v-btn>
        <h2>{{ $t('editRole') }}</h2>
      </div>
      <v-btn
        icon
        @click="$emit('close')"
      >
        <v-icon color="black">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>
  
  <script>
  export default {
    name: 'HeaderEdit',
    methods: {
        backToRoles() {
            this.$router.push({ name: 'Roles' });
        },
    },
  }
  </script>
  