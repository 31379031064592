<template>
  <v-card
    class="white py-6 px-6"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>{{ title }}</h2>
      <slot name="action">
        <v-btn
          v-if="hasAction"
          color="blue"
          dark
          class="text-capitalize"
          @click="$emit('create')"
        >
          {{ actionText }}
          <v-icon
            class="ml-1"
            size="xs"
          >
            mdi-plus
          </v-icon>
        </v-btn>
      </slot>
    </div>
    <slot name="additional-actions" />
  </v-card>
</template>

<script>
export default {
  name: 'SectionHeader',

  props: {
    title: String,
    actionText: String,
    hasAction: {
      type: Boolean,
      default: true,
    }
  },
}
</script>
