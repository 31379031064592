<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('myWorkspace') }}</h2>
    </div>
    <div class="mt-4 d-flex justify-between">
      <div class="filter-chips">
        <v-chip 
          :class="{ 'white--text': filter === 'todo' }" 
          class="mr-2"
          width="200px" 
          :color="filter === 'todo' ? 'blue' : '#f9fafb'"
          label 
          @click="$emit('updateView', 'todo')"
        >
          <div class="font-weight-bold px-2">
            {{ $t('todo') }} <span class="ml-2">{{ todoCount }}</span>
          </div>
        </v-chip>
        <v-chip 
          :class="{ 'white--text': filter === 'completed' }" 
          width="200px" 
          :color="filter === 'completed' ? 'blue' : '#f9fafb'"
          label 
          @click="$emit('updateView', 'completed')"
        >
          <div class="font-weight-bold px-2">
            {{ $t('completed') }} <span class="ml-2">{{ completedCount }}</span>
          </div>
        </v-chip>
      </div>
      <div
        v-if="progressCalculation"
        class="d-flex flex-row align-center justify-space-between ml-6"
      >
        <progress-bar
          :progresses="progressCalculation"
        />
      </div>
    </div>
    <div class="__workspace_filters">
      <v-row class="mt-2">
        <v-col>   
          <v-autocomplete
            v-model="selectedProjects"
            type="text"
            dense
            single-line
            filled
            :placeholder="$t('allProjects')"
            hide-details
            small-chips
            hide-selected
            multiple
            :items="projects"
            item-value="uid"
            item-text="name"
            class="rounded-lg"
            clear-icon="body-2"
          />
        </v-col>
        <v-col>   
          <v-autocomplete
            v-model="selectedMilestones"
            type="text"
            dense
            single-line
            filled
            :placeholder="$t('allMilestones')"
            hide-details
            multiple
            small-chips
            hide-selected
            :items="milestones"
            item-text="name"
            item-value="uid"
            class="rounded-lg"
            clear-icon="body-2"
          />
        </v-col>
        <v-col>   
          <v-autocomplete
            v-model="selectedPlans"
            type="text"
            dense
            single-line
            filled
            :placeholder="$t('allPlans')"
            hide-details
            multiple
            small-chips
            hide-selected
            item-text="name"
            item-value="uid"
            :items="testPlans"
            class="rounded-lg"
            clear-icon="body-2"
          />
        </v-col>
        <v-col>   
          <v-autocomplete
            v-model="selectedRuns"
            type="text"
            dense
            single-line
            filled
            :placeholder="$t('allRuns')"
            hide-details
            multiple
            small-chips
            hide-selected
            :items="runs"
            item-text="name"
            item-value="uid"
            class="rounded-lg"
            clear-icon="body-2"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex">     
          <v-btn
            color="blue"
            class="white--text mr-2"
            dense
            @click="updateFilter"
          >
            <v-icon>mdi-magnify</v-icon>
            <span class="mx-2">{{ $t('search') }}</span>
          </v-btn>
          <v-btn
            dense
            @click="resetFilters"
          >
            <span class="mx-2">{{ $t('reset') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import ProgressBar from '@/components/Execution/ProgressBar'
export default {
  components:{
    ProgressBar
  },
  props: {
    filter: String,
    todoCount: Number,
    completedCount: Number,
    filterData: Object,
    progressCalculation: Object
  },
  data(){
    return{
      projects: this.filterData.projects,
      selectedProjects: [],
      selectedMilestones: [],
      selectedRuns: [],
      selectedPlans: [],
    }
  },
  computed:{
    milestones(){
      return this.filterData.milestones.filter(element => this.selectedProjects.includes(element.projectUid))
    },
    testPlans(){
      return this.filterData.testPlans.filter(testPlan => {
        if(testPlan.milestoneUids.some(obj => this.selectedMilestones.includes(obj.uid)))
          return testPlan
      })
    },
    runs(){
        const runs =  this.filterData.testRuns.filter(run => {
          if(this.selectedPlans.length && this.selectedPlans.includes(run.testPlanUid))
            return false
          if (run.milestoneUids.some(obj => this.selectedMilestones.includes(obj.uid))) {
            return run
          }
        })
      return runs

    }
  
  },
  methods: {
    updateFilter() {
      this.$emit('update-filters', {
        projects: this.selectedProjects.length ? this.selectedProjects : undefined ,
        milestones: this.selectedMilestones.length ? this.selectedMilestones : undefined,
        testPlanUids: this.selectedPlans ? this.selectedPlans : undefined,
        testRuns: this.selectedRuns.length ? this.selectedRuns : undefined,
      });
    },
    resetFilters(){
      this.selectedMilestones = []
      this.selectedPlans = []
      this.selectedRuns = []
      this.selectedProjects = []
      
      this.updateFilter();
    },
  }
}
</script>
<style>
.__workspace_filters .row .col .v-select__selections input{
  min-height: 46px !important;
}
</style>