<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="table-fixed data-table-style mt-6"
    :headers="filteredHeaders"
    :items="itemsPerView"
    :item-key="itemKey"
  >
    <template v-slot:[`item.name`]="{ item }">
      <v-checkbox
            v-model="item.toBeSelected"
            :value="item.toBeSelected"
            class="field-theme"
            hide-details
            dense
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            @change="toBeSelectedHandler"
            >
            <template v-slot:label>
              <div class="text-subtitle-2 text-truncate font-weight-bold">
                {{ item.name }}
              </div>
            </template>
      </v-checkbox>
    </template>
    <template />
    <template v-slot:[`item.testcases`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-3">
            {{ item.testcases }} test cases
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.testruns`]="{ item }">
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.runs?.length }} {{ $t('milestone.addTestPlan.testRuns') }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.progress`]="{ item }">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-progress-linear
          rounded
          :color="getProgress(item.progress) > 70 ? '#F2284E' : '#0c2ff3'"
          background-color="#F2F4F7"
          :height="8"
          :value="getProgress(item.progress)"
        />
        <span class="font-weight-regular ml-3">{{ getProgress(item.progress) }}%</span>
      </div>
    </template>

    <template v-slot:[`header.actions`]="{header}">
      <div class="d-none">
        {{ header.text }}
      </div>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span :class="getColor(item.priority)">{{ item.priority }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span :class="getColor(item.status)">{{ item.status }}</span>
    </template>
    <template v-slot:[`item.configurations`]="{item}">
      <span>{{ item.customFields?.configurations }}</span>
    </template>
    <template v-slot:[`item.testcases`]="{item}">
      <span class="">{{ item.customFields.caseCount }} test cases</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ formatDueDate(item.createdAt) }}</span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <v-tooltip
        top
        left
        max-width="485px"
        :disabled="!Array.isArray(item.customFields?.tags) || item.customFields.tags?.length < 2"
        content-class="tooltip-theme"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="custom-attribute font-weight-regular text-theme-table-text"
            v-bind="attrs"
            v-on="on"
          >
            <template>
              <div class="text-truncate">
                {{
                  Array.isArray(item.customFields?.tags)
                    ? item.customFields.tags?.length > 0
                      ? item.customFields.tags.map(tag => `${tag}`).join(', ')
                      : $t('no_tags')
                    : typeof item.customFields?.tags === 'string' && item.customFields.tags.trim() !== ''
                      ? item.customFields.tags
                      : $t('no_tags')
                }}
              </div>
            </template>
          </span>
        </template>
        <span>
          {{
            Array.isArray(item.customFields?.tags)
              ? item.customFields.tags.map(tag => `${tag}`).join(', ')
              : ''
          }}
        </span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
  
<script>
import { dateMixin } from '@/mixins/date.js';

export default {
    mixins: [dateMixin],
    props: {
        tableFilter: {
            type: String,
            default: 'all',
        },
        plansData: {
            type: Array,
        },
        filteredHeaders: {
            type: Array,
        },
        filteredItems: {
            type:Array,
        },
        rowClass: {
            type:Function,
        }
    },
    data() {
        return {
            plans: this.plansData,
            itemKey: 'uid',
            isAllPlanChecked: false,
            isSelectedPlanChecked: false,
        };
    },
    computed: {
        itemsPerView() {
            if (this.tableFilter === 'all') {
                return this.filteredItems?.filter((item) => !item.selected);
            } else {
                return this.filteredItems?.filter((item) => item.selected);
            }
        },
    },
    watch: {
        tableFilter(newValue) {
            this.togglePlans();
        },
    },
    methods: {
      formatDueDate(dueDate) {
        return this.formatDate(dueDate)
      },
      getProgress(progress) {
      const numericProgress = parseFloat(progress);
        return isNaN(numericProgress) ? 0 : numericProgress;
      },
      getColor(priority) {
        switch(priority){
          case "High":
          case "Failed":
            return "font-weight-bold red--text text--lighten-1"
          case "Medium":
          case "Rerun":
            return "font-weight-bold orange--text text--lighten-1"
          case "Low":
          case "Active":
            return "font-weight-bold green--text text--lighten-1"
          case "Passed":
            return "font-weight-bold deep-purple--text text--lighten-1"
          case "In progress":
            return "font-weight-bold grey--text text--lighten-1"
        }
      },
        togglePlans() {
            const isSelected = this.tableFilter === 'all' ? this.isAllPlanChecked : this.isSelectedPlanChecked;
            this.plans.forEach((plan) => {
                const condition = this.tableFilter === 'all' ? !plan.selected : plan.selected;
                if (condition) {
                    this.$set(plan, 'toBeSelected', isSelected);
                }
            });
        },
        toBeSelectedHandler() {
            const filteredPlans =
                this.tableFilter === 'all'
                    ? this.plans?.filter((plan) => !plan.selected)
                    : this.plans?.filter((plan) => plan.selected);
            const allTrue = filteredPlans.every((plan) => plan.toBeSelected);

            if (this.tableFilter === 'all') {
                this.isAllPlanChecked = allTrue;
            } else {
                this.isSelectedPlanChecked = allTrue;
            }
        },
    },
};
</script>
  