import i18n from '../../i18n';
import makeProjectsService from '@/services/api/project';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

const state = {
  projects: [],
  selectedProject: null,
  totalArchived: 0,
  totalActive: 0,
};

const mutations = {
  SET_PROJECTS: (state, projects) => (state.projects = projects),
  SET_SELECTED_PROJECT: (state, project) => (state.selectedProject = project),
  ADD_PROJECT: (state, project) => state.projects.unshift(project),
  DELETE_PROJECT: (state, projectKey) =>
    (state.projects = state.projects.filter((project) => project.key !== projectKey)),
  UPDATE_PROJECT: (state, updatedProject) => {
    const index = state.projects.findIndex((project) => project.key === updatedProject.key);
    if (index !== -1) {
      state.projects.splice(index, 1, updatedProject);
    }
  },
  SET_TOTAL_ARCHIVED: (state, total) => {
    state.totalArchived = total;
  },
  SET_TOTAL_ACTIVE: (state, total) => {
    state.totalActive = total;
  },
};

const makeActions = (api) => {
  const projectsService = makeProjectsService(api);
  return {
    get: async function ({ commit }, { handle, params }) {
      const response = await projectsService.getProjects(handle, params);
      commit('SET_PROJECTS', response.data.projects);

      if (response.data.meta) {
        commit('SET_TOTAL_ARCHIVED', response.data.meta?.totalArchived || 0);
        commit('SET_TOTAL_ACTIVE', response.data.meta?.totalActive || 0);
      }
    },
    add: async function ({ commit }, { swal, handle, payload }) {
      const response = await projectsService.createProject(handle, payload);
      commit('ADD_PROJECT', response.data);
      showSuccessToast(swal, i18n.t('success.projectCreated'));
      return response;
    },
    delete: async function ({ commit }, { swal, handle, projectKey }) {
      await projectsService.deleteProject(handle, projectKey);
      commit('DELETE_PROJECT', projectKey);
      showSuccessToast(swal, i18n.t('success.projectDeleted'));
    },
    update: async function ({ commit }, { swal, handle, oldProject, payload }) {
      try {
        await projectsService.updateProject(handle, oldProject.key, payload);
        commit('UPDATE_PROJECT', { ...oldProject, ...payload });
        showSuccessToast(swal, i18n.t('success.projectUpdated'));
      } catch (err) {
        if (err.response.status === 422) {
          showErrorToast(swal, err.response.data.message);
        }
      }
    },
  };
};

const getters = {
  projects(state) {
    return state.projects;
  },
  selectedProject(state){
    return state.selectedProject;
  }
};

export default function makeProject(api) {
  return {
    namespaced: true,
    state,
    mutations,
    getters,
    actions: makeActions(api),
  };
}
