<template>
  <v-card
    class="white py-6 px-6"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('customFields') }}</h2>
      <v-btn
        class="text-capitalize btn-theme"
        color="primary"
        depressed
        height="38px"
        @click="$emit('create-custom-field')"
      >
        {{ $t('customFieldPage.createCustomField') }}
        <v-icon
          class="ml-1"
          size="xs"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CustomFieldHeader',
}
</script>
