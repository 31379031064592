<template>
  <v-row
    align-content="start"
    justify="start"
    dense
  >
    <div
      :style="{ width: isTreeViewCollapsed ? '5%' : '15%' }"
      class="mt-4"
    >   
      <div
        class="d-flex flex-column white card rounded-lg ml-1 mr-2 sticky-on-scroll"
        :class="{ 'is-collapsed-menu': isTreeViewCollapsed }"
      > 
        <TreeView
          :items="folders"
          :execution-folders="executionFolders"
          :selected-folder-uid="selectedFolderUid"
          :collapsed="isTreeViewCollapsed ? 0 : 1"
          :hide-create="showCreate"
          @folder-selected="selectFolder"
          @folder-delete="deleteFolder"
          @root-folder="setRootFolder"
        />
        <div
          v-if="showCollapse"
          class="collapse-btn"
          @click="toggleMenu"
        >
          <v-icon
            color="#0C2FF3"
          >
            {{ isTreeViewCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'
            }}
          </v-icon>
          <span
            v-if="!isTreeViewCollapsed"
            class="collapse-text"
          >{{ $t('collapse') }}</span>
        </div>
      </div> 
    </div>
    
    <v-col
      class=""
      :style="{ width: isTreeViewCollapsed ? '95%' : '85%' }"
    >
      <v-row
        align-content="start"
        justify="start"
        dense
      >
        <div
          :class="[isDetailViewCollapsed ? 'col-7' : 'col-12']"
          class="col mt-3"
        >
          <execution-list
            id="cases-list"
            :case-items="filterCases"
            :execution="execution"
            :bread-crumbs="breadCrumbs"
            :allow-action="allowAction"
            :is-repository="!quickCreate"
            :is-detail-collapsed="isDetailViewCollapsed"
            :assignees="assignees"
            @updateExecutions="updateExecutions"
            @expandDetail="openDetailView"
            @createCase="onCreateCase"
            @refresh="selectFolder"
            @selectedExecutions="handleExecutions"
            @updateExecution="updateExecution"
          />
          <slot name="control-area" />
        </div>
        <div
          v-if="isDetailViewCollapsed && execution"
          class="col-5 mt-3"
        >
          <detail-view
            :execution="execution"
            :test-results="testResults"
            @updateExecution="updateExecution"
            @addResult="addResult"
            @updateResult="updateResult"
            @deleteResult="deleteResult"
            @moveItem="moveItem"
            @closeDetail="closeDetailView"
          />
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import ExecutionList from './List.vue';
import TreeView from '@/views/Tests/Case/Tree/Index.vue';
import DetailView from './Index.vue';
import { showErrorToast } from '@/utils/toast';
import makeCasesService from '@/services/api/case';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');
export default {
  components: {
      ExecutionList,
      TreeView,
      DetailView
  },
  props:{
    /*
      showCreate: Enables the creation of new folders.
      showCollapse: Hides the collapse folder option.
      selectOption: Returns selected cases only when set to true.
      allowAction: Activates the action button for selected cases.
    */
    showCreate:{
      type: Boolean,
      default: true,
    },
    showCollapse:{
      type: Boolean,
      default: true
    },
    selectOption:{
      type: Boolean,
      default: false,
    },
    allowAction:{
      type: Boolean,
      default: true
    },
    quickCreate:{
      type: Boolean,
      default: false
    },
    executions:{
      type: Array,
    },
    testResults:{
      type: Array,
    },
    execution:{
      type: Object,
    },
    assignees: {
      type: Array
    },
  },
  data() {
    return {
      isTreeViewCollapsed: false,
      isDetailViewCollapsed: false,
      selectedItem : null,
      folders: [],
      breadCrumbs: [],
      selectedCase: {},
      selectedCases: [],
      selectedFolderUid: this.$route.params.folderUid? this.$route.params.folderUid: null,
    };
  },
  computed: {
  ...mapState(['currentAccount']),
  cases(){
    if(this.executions.length)
        return this.executions.filter(element => element.parentUid == this.selectedFolderUid)
    return []
  },
  executionFolders(){
    return this.executions.map(item => item.parentUid)
  },
  filterCases(){
    if(this.selectOption)
      return this.selectedCases

    return this.cases
    },
  },
  methods: {
    // For Testrun
    toggleMenu() {
      this.isTreeViewCollapsed = !this.isTreeViewCollapsed
    },
    setRootFolder(treeitems) {
      const rootFolder = treeitems.find((folder) => folder.parentUid === null);
      this.selectedFolderUid = rootFolder.uid;
    },
    changeExpansion(item) {
      this.selectedItem = item
      this.isDetailViewCollapsed = true;

    },
    closeDetailView() {
      this.isDetailViewCollapsed = false;
    },
    handleExecutions(selectedCases){
      this.selectedCases = selectedCases
      this.$emit('updateSelectedExecution', selectedCases)
    },
    buildBreadCrumbs(searchUID, currentFolders, chain) {
      for (let idx = 0; idx < currentFolders.length; idx++) {
        let folder = currentFolders[idx];
        if (folder.uid === searchUID) {
          chain.push({ text: folder.name });
          this.breadCrumbs = chain;
          break;
        } else if (folder.children && folder.children.length > 0) {
          let newChain = [...chain, { text: folder.name }];
          this.buildBreadCrumbs(searchUID, folder.children, newChain);
        }
      }
    },
    async selectFolder(folderUID = null) {
      if(folderUID)
          this.selectedFolderUid = folderUID
      else
          folderUID = this.selectedFolderUid

      this.buildBreadCrumbs(folderUID, this.folders, []);
      this.$emit('folder-select', folderUID)
    },
    async onCreateCase(caseItem) {
      const caseService = makeCasesService(this.$api);
      try {
        const res = await caseService.createTestCase(
          this.$route.params.handle,
          this.$route.params.key,
          caseItem
        );
        if (res.status == 200) {
          let caseItem = res.data;
          this.cases.unshift(...caseItem);
        } else {
          showErrorToast(this.$swal, this.$t('error.caseAddFailed'));
        }
      } catch (error) {
        showErrorToast(this.$swal, this.$t('error.caseAddFailed'));
        console.log("Error: ", error);
      }
    },
    updateResult(selectedResultUid, payload){
      this.$emit('updateResult',selectedResultUid, payload)
    },
    addResult(payload){
      this.$emit('addResult', payload)
    },
    deleteResult(resultUid){
      this.$emit('deleteResult', resultUid)
    },
    updateExecution(payload){
      this.$emit('updateExecution', payload)
    },
    updateExecutions(payload){
      this.$emit('updateExecutions', payload)
    },
    moveItem(direction){
      this.$emit('moveItem', direction)
    },
    // Keep
    openDetailView(item) {
      if(this.selectedCase?.uid == item.uid)
        return;
        
      this.selectedCase = item;
      this.isDetailViewCollapsed = true;
      this.$emit('getExecution', item.uid);
    },
    deleteFolder(){
      this.cases = []
    },
  },
}
</script>
<style scoped>
.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  height: calc(100vh - 24px);
}
</style>
