<template>
    <div class="d-flex justify-center align-center flex-column">
        <img :src="imageSrc" alt="Empty state image" class="placeholder-img mb-6" />
        <div>
            <div class="d-flex flex-column align-center">
                <p class="ma-0 font-weight-bold fs-24px">
                    {{ title }}
                </p>
                <slot name="description"></slot>
            </div>
            <div class="mt-6 align-center justify-center">
                <div>
                    <v-btn dark depressed height="40px" :color="buttonColor"
                        class="text-capitalize white--text rounded-lg btn-theme" :to="buttonRoute">
                        {{ buttonText }}
                        <v-icon class="ml-1" size="16">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
        buttonColor: {
            type: String,
            default: 'blue',
        },
        buttonRoute: {
            type: Object,
            required: true,
        },
    },
};
</script>