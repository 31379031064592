<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('roles') }}</h2>
      <v-btn
        color="blue"
        dark
        class="text-capitalize"
        @click="$emit('create-new-role')"
      >
        {{ $t('createRole') }}
        <v-icon
          class="ml-1"
          size="xs"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>
  
  <script>
  export default {
    name: 'RoleHeader',
  }
  </script>
  