import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import makeUser from './modules/user';
import makeTest from './modules/test';
import makeAccessToken from './modules/accessToken';
import makeTestCase from './modules/case';
import makeComment from './modules/comment';
import makeFolder from './modules/folder';
import dashboard from './modules/dashboard';
import makeProject from './modules/project';
import makeOrg from './modules/org';
import makeRun from './modules/run';
import makeMilestone from './modules/milestone';
import makePlan from './modules/plan';
import makeTemplate from './modules/template';
import makeAttachment from './modules/attachment';
import makeHeader from './modules/tableHeaders';
Vue.use(Vuex);

export default function makeStore(api) {
  return new Vuex.Store({
    state: {
      isProjectMenuCollapsed: false,
      isMenuCollapsed: false,
      isTestMenuCollapsed: false,
      isLoading: false,
      loadingText: '',
    },
    mutations: {
      toggleMenu(state) {
        state.isMenuCollapsed = !state.isMenuCollapsed;
      },
      toggleProjectMenu(state) {
        state.isProjectMenuCollapsed = !state.isProjectMenuCollapsed;
      },
      toggleTestMenu(state) {
        state.isTestMenuCollapsed = !state.isTestMenuCollapsed;
      },
      setLoading(state, { loading, loadingText }) {
         // TODO - do something better than the current overlay when we uncomment
        //        the below
        // state.isLoading = loading;
        // state.loadingText = loadingText;
      },
    },
    actions: {
      setLoading({ commit }, { loading, loadingText }) {
        // commit('setLoading', { loading, loadingText });
      },
    },
    getters: {
      isMenuCollapsed: (state) => state.isMenuCollapsed,
      isProjectMenuCollapsed: (state) => state.isProjectMenuCollapsed,
      isTestMenuCollapsed: (state) => state.isTestMenuCollapsed,
      isLoading: (state) => false, //state.isLoading,
      loadingText: (state) => state.loadingText,
    },
    modules: {
      user: makeUser(),
      headers: makeHeader(),
      test: makeTest(),
      accessToken: makeAccessToken(api),
      dashboard: dashboard,
      tests: makeTest(),
      case: makeTestCase(api),
      attachment: makeAttachment(api),
      comment: makeComment(api),
      folder: makeFolder(api),
      project: makeProject(api),
      org: makeOrg(api),
      run: makeRun(api),
      milestone: makeMilestone(api),
      plan: makePlan(api),
      template: makeTemplate(api),
    },
    plugins: [
      createPersistedState({
        paths: [
          'user',
          'test',
          'headers',
          'accessToken',
          'dashboard',
          'tests',
          'case',
          'comment',
          'folder',
          'project',
          'org',
          'run',
          'milestone',
          'plan',
          'template',
        ],
      }),
    ],
  });
}
