<template>
  <v-dialog
    v-model="showDialog"
    class="test-cases-filter-drawer dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
  >
    <v-card class="d-flex flex-column justify-space-between overflow-hidden">
      <v-card-text class="black--text mt-8">
        <div class="d-flex align-center justify-space-between">
          <h2 class="black--text">
            {{ isEditMode ? $t('templatesPage.editTemplate') : $t('templatesPage.createTemplate') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="d-flex flex-column mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-left">
                <v-label class="fs-14px text-theme-label font-weight-medium">
                  {{ $t('templatesPage.template_name') }}
                </v-label>
              </div>
              <v-text-field
                v-model="customTemplate.name"
                type="text"
                class="rounded-lg field-theme"
                background-color="#F9F9FB"
                dense
                height="38px"
                :placeholder="$t('name')"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>

          <v-row class="px-3 mb-4">
            <v-row
              v-for="(item, index) in fields"
              :key="index"
              class="px-0"
            >
              <CustomFieldForm
                :custom-fields="customTemplate.customFields"
                :field-index="index"
                :field-item="item"
                @add-option="onAddOption"
                @remove-field="removeItem"
              />
            </v-row>
          </v-row>

          <v-row>
            <v-col class="d-flex flex-row justify-end">
              <v-menu
                offset-y
                left
                :nudge-bottom="4"
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    height="40"
                    depressed
                    class="text-capitalize white--text btn-theme rounded-lg"
                    v-on="on"
                  >
                    {{ $t('templatesPage.add_field') }}
                    <v-icon color="white">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item :key="1" @click="handleAddExistingFieldClick">
                      {{ $t('templatesPage.add_exist_fields') }}
                  </v-list-item> -->
                  <v-list-item
                    :key="2"
                    @click="handleAddCustomFieldClick"
                  >
                    {{ $t('templatesPage.add_new_custom_field') }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="actions-container d-flex justify-space-between">
      <v-btn
        width="204.5px"
        color="#F2F4F7"
        class="text-capitalize btn-theme"
        :depressed="true"
        full-width
        elevation="0"
        height="40"
        @click="onCancel()"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        width="204.5px"
        class="btn-theme"
        height="40"
        color="primary"
        :depressed="true"
        full-width
        elevation="0"
        @click="onCreate()"
      >
        {{ isEditMode ? $t('save') : $t('create') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import CustomFieldForm from '@/components/Admin/CustomTemplates/CustomFieldForm.vue';
import { uuid } from 'vue-uuid';
// import makeCustomFieldService from '@/services/api/customField';

export default {
  name: 'CreateUpdateCustomField',

  components: {
    CustomFieldForm,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      requiredRules: [(value) => !!value || this.$t('error.requiredField')],
      validForm: false,
      fields: [],
      customTemplate: {
        uid: '',
        name: '',
        customFields: [],
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },

    isEditMode() {
      return !!this.customTemplate.uid;
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return;
      }

      this.customTemplate = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        customFields: this.data.customFields || [],
      };

      this.fields = this.data.customFields || [];

    },
  },
  created() {
    // await this.getCustomFields(this.$route.params.handle)
  },
  methods: {
     getCustomFields(handle) {
      // const customFieldService = makeCustomFieldService(this.$api);
      // await customFieldService.getCustomFields(handle).then((response) => {
      //   this.customFields = response.data;
      // });
    },
    handleAddCustomFieldClick() {
      const newItem = {
        id: uuid.v4(),
        name: '',
        dataType: 'text',
        defaultValue: '',
        options: [],
        default_date: new Date().toISOString().substr(0, 10),
      };

      this.fields.push(newItem);
    },
    handleAddExistingFieldClick() {
      const newItem = {
        name: '',
        dataType: 'existing_field',
        defaultValue: [],
        default_date: new Date().toISOString().substr(0, 10),
      };

      this.fields.push(newItem);
    },
    onAddOption(field) {
      const index = this.fields.indexOf(field);

      const options = [...field.options, ''];

      const newField = {
        id: field.id,
        name: field.name,
        dataType: field.dataType,
        defaultValue: field.defaultValue,
        options,
      };

      this.fields.splice(index, 1, newField);
    },

    removeItem(selectedItem) {
      console.log(selectedItem);
      this.fields = this.fields.filter((item) => item != selectedItem);
      console.log(this.fields);
    },

    onCreate() {
      const isValidForm = this.$refs.form.validate();

      if (!isValidForm) {
        return;
      }

      this.customTemplate.customFields = this.fields;

      this.$emit(this.isEditMode ? 'update-template' : 'create-template', this.customTemplate);
    },

    onCancel() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.font-16 {
  font-size: 16px;
}

.bg-color-grey {
  background-color: #f9fafb;
}
.custom-switch {
  ::v-deep .v-input--switch__thumb {
    width: 24px;
    height: 24px;
    top: 0;
    right: 2px;
  }

  ::v-deep .primary--text {
    background-color: #ffffff !important; /* Custom track color when switch is on */
  }

  ::v-deep .primary--text.v-input--switch__track {
    background-color: #0000ff !important; /* Custom thumb color */
    opacity: 1;
  }
}

.bg-f9f9fb {
  background-color: #f9fafb;
}

.swal-back {
  width: 440px;
}
</style>
