<template>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card class="d-flex flex-column justify-space-between overflow-hidden">
        <v-card-text class="black--text mt-8">
          <div class="d-flex align-center justify-space-between mb-8">
            <h2 class="black--text">
                {{ templateName }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <div class="flex justify-between mb-6">
              <div
                class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px bg-gray-theme"
              >
                <h5 class="align-left">
                  {{ $t('templatesPage.creator') }}
                </h5>
                <div class="align-left contents fw-semibold fs-14px">
                    {{ templateCreator }}
                </div>
              </div>
              <div
                class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px bg-gray-theme"
              >
                <h5 class="align-left">
                  {{ $t('creationDate') }}
                </h5>
                <div
                  class="align-left contents fs-14px fw-semibold"
                >
                    {{ templateCreatedAt }}
                </div>
              </div>
            </div>

            <template v-if="templateCustomFieldsNotEmpty">
                <div v-for="(field, index) in templateCustomFields" :key="index"
                class="mb-6"
                >
                    <h4 class="fw-semibold fs-16px text-theme-label mb-3">
                        {{ `#${index + 1}` }} {{ field.name }}
                    </h4>
                    <div class="flex justify-between">
                        <div
                            class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px bg-gray-theme"
                        >
                            <h5 class="align-left">
                            {{ $t('name') }}
                            </h5>
                            <div class="align-left contents fw-semibold fs-14px">
                                {{ field.name }}
                            </div>
                        </div>
                        <div
                            class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px bg-gray-theme"
                        >
                            <h5 class="align-left">
                            {{ $t('dataType') }}
                            </h5>
                            <div
                            class="align-left contents fs-14px fw-semibold"
                            >
                                {{ field.dataType }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>



        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          class="text-capitalize btn-theme"
          :depressed="true"
          full-width
          elevation="0"
          height="40"
          @click="onCancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="onEdit"
        >
        {{ $t('edit') }}
        </v-btn>
      </div>
    </v-dialog>
  </template>
  
  <script>
  import { formattedDate } from '@/utils/util';

  export default {
    name: 'ViewDetailDialog',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
  
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        
      };
    },
    computed: {
      showDialog: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
      templateName() {
        return this.data?.name;
      },
      templateCreator(){
        return `${ this.data?.creator.firstName } ${ this.data?.creator.lastName }`;
      },
      templateCreatedAt(){
        return this.data?.createdAt ? formattedDate(this.data?.createdAt) : '';
      },
      templateCustomFields(){
        return this.data?.customFields;
      },
      templateCustomFieldsNotEmpty(){
        return this.data?.customFields.length > 0;
      },
    },
  
    watch: {
      showDialog(value) {
        if (!value) {
          return;
        }
      },
    },
    methods: {
        onCancel() {
            this.showDialog = false;
        },
        onEdit() {
            this.showDialog = false;
            this.$emit('editTemplate', this.data);
        }
    },
  };
  </script>
  
  