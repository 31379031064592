import ProjectLayout from '@/Layouts/ProjectLayout.vue';

const routes = [
  {
    path: '/:handle/:key/milestones',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'Milestones',
        component: () => import('@/views/Tests/Milestones/Index'),
      },
      {
        path: 'create',
        name: 'MilestoneCreate',
        component: () => import('@/views/Tests/Milestones/MilestoneCreateView'),
      },
      {
        path: 'testrun',
        name: 'MilestoneTestRun',
        component: () => import('@/views/Tests/Milestones/MilestoneTestRunView'),
      },
      {
        path: 'testplan',
        name: 'MilestoneTestPlan',
        component: () => import('@/views/Tests/Milestones/MilestoneTestPlanView'),
      },
      {
        path: 'testactivities',
        name: 'MilestoneTestActivities',
        component: () => import('@/views/Tests/Milestones/MilestoneTestActivitiesView'),
      },
      {
        path: 'edit/:id',
        name: 'MilestoneEdit',
        component: () => import('@/views/Tests/Milestones/MilestoneEditView'),
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
