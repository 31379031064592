<template>
  <v-card
    class="white mt-3 left-menu-card sticky-on-scroll pt-4"
    rounded="lg"
    elevation="0"
    :class="{ 'is-collapsed-menu': isProjectMenuCollapsed }"
  >
    <v-select
      v-model="selectedProject"
      :items="projects"
      item-text="name"
      dense
      item-value="key"
      class="rounded-lg field-theme custom-prepend mx-5 select-input-none"
      append-icon="mdi-chevron-down"
      background-color="#F9F9FB"
      min-height="40px"
      :menu-props="{ offsetY: true }"
    />

    <v-list
      :class="isProjectMenuCollapsed ? 'px-3' : 'px-5'"
      nav
    >
      <div
        v-for="(item, index) in menuItems"
        :key="index"
      >
        <div class="text-left ml-3 my-4 group">
          <span class="fs-16px font-weight-regular disabled-action">{{ item.group }}</span>
        </div>
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item
            v-for="(childItem) in item.list"
            :key="childItem.id"
            :to="childItem.to"
            class="mh-36px"
            :class="{ 'px-3': !isProjectMenuCollapsed, 'mb-3': isProjectMenuCollapsed }"
            active-class="active-menu-item"
            @click="setActive(childItem.id)"
          >
            <v-list-item-icon
              v-if="!isProjectMenuCollapsed"
              class="my-3 mr-2"
              :class="childItem.className"
            >
              <component :is="childItem.icon" />
            </v-list-item-icon>
            <v-list-item-content
              class="pa-0"
              :class="childItem.className"
            >
              <component
                :is="childItem.icon"
                v-if="isProjectMenuCollapsed"
              />
              <v-list-item-title
                v-if="!isProjectMenuCollapsed"
                class="text-left"
              >
                <span class="fs-14px font-weight-regular text-theme-secondary">{{ childItem.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>
    </v-list>
    
    
    <div
      class="collapse-btn"
      @click="toggleProjectMenu"
    >
      <v-icon
        class="collapse-icon"
        color="#0C2FF3"
      >
        {{ isProjectMenuCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'
        }}
      </v-icon>
      <span
        v-if="!isProjectMenuCollapsed"
        class="collapse-text"
      >
        {{ $t('collapse') }}
      </span>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
import ProjectsService from '@/services/api/project';
import { showErrorToast } from '@/utils/toast';

const { mapState: mapProjectState, mapMutations: mapProjectMutations } = createNamespacedHelpers('project');
const { mapMutations: mapFolderMutations } = createNamespacedHelpers('folder');

let makeProjectService;

export default {
  name: 'ProjectLeftMenuCard',
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    }
  },

  data()
  {
    return {
      handle: this.$route.params.handle,
      selectedItem: 0,
      activeIndex: null,
      filter: 'active',
    };
  },
  computed: {
    ...mapGetters(['isProjectMenuCollapsed']),
    ...mapProjectState(['projects']),
    selectedProject: {
      get() {
        return this.$route.params.key;
      },
      set(key) {
        this.SET_SELECTED_PROJECT(
          this.projects.find((project) => key === project.key)
        );
        this.UPDATE_SELECTED({});
        this.reloadCurrentRoute(key);
      },
    },
  },
  created() {
      makeProjectService = ProjectsService(this.$api);
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    ...mapMutations(['toggleProjectMenu']),
    ...mapProjectMutations(['SET_SELECTED_PROJECT', 'SET_PROJECTS']),
    ...mapFolderMutations(['UPDATE_SELECTED']),

    setActive(index)
    {
        this.$nextTick(() =>
        {
          this.selectedItem = index;
          this.activeIndex = index;
        });
    },
    reloadCurrentRoute(key)
    {
      const currentRoute = this.$route;
      const newParams = { ...currentRoute.params, key };
      this.$router.replace({
        name: currentRoute.name,
        params: newParams,
        query: currentRoute.query
      }).then(() =>
      {
        this.$router.go();
      })
    },
    async getProjects() {
      const searchParams = new URLSearchParams();
      searchParams.set('status', this.filter);
      searchParams.set('includeCount', true); 
      try {
        const response = await makeProjectService.getProjects(this.handle, searchParams.toString());
        this.SET_PROJECTS(response.data.projects);
      } catch (error) {
          showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      }
    },
  },
};
</script>

<style scoped>
.left-menu-card {
  position: relative;
  height: calc(100vh - 8rem);
  transition: width 0.3s;
}

.text-left {
  text-align: left;
}

.left-menu-card.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  height: calc(100vh - 24px);
}


.left-menu-card.collapsed .collapse-btn .collapse-text {
  display: none;
}

.left-menu-card.collapsed .collapse-btn {
  justify-content: center;
}

.v-select .v-select__selections {
  white-space: normal !important;
  overflow: visible !important;
}

.v-select .v-select__selection {
  white-space: normal !important;
  word-wrap: break-word;
}
.v-select input {
  display: none;
}
</style>
