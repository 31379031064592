import credentialService from "@/services/local/credential";
import settingsService from "@/services/local/settings";

// CTODO - Shouldn't be a factory external api access should not be in state files
const state = {
  user: credentialService.getUser(),
  orgs: credentialService.getOrgs(),
  currentAccount: settingsService.getCurrentAccount(),
};

const getters = {
  userName(state) {
    return state.user ? `${state.user.first_name} ${state.user.last_name}` : "";
  },
  isAuthenticated(state) {
    return state?.user;
  },
  currentAccount(state) {
    return state?.currentAccount;
  },
  user(state) {
    return state?.user;
  },
  accounts(state) {
    return state.accounts;
  },
  orgs(state) {
    return state.orgs;
  },
  /**
   * returns true if the current user is org admin
   * @param {String} handle
   * @return {Boolean} isAdmin
   */
  isOrgAdmin: (state) => (handle) => {
    let org = state.orgs.filter((org) => org.handle == handle)[0];
    return org && org.roleName !== "member";
  },
  /**
   * get org with org handle
   * @param {String} handle
   * @return {Object} org
   */
  getOrg: (state) => (handle) => {
    return state.orgs.filter((org) => org.handle == handle)[0];
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  updateUser(state, user) {
    state.user = { ...state.user, ...user };
  },
  setOrgs(state, orgs) {
    state.orgs = orgs;
  },
  updateOrg(state, org){
    const findIndex = state.orgs.findIndex(element => element.uid == org.uid)
    state.orgs[findIndex] = org;
  },
  setCurrentAccount(state, currentAccount) {
    state.currentAccount = currentAccount;
  },
  emptyState(state) {
    state.user = null;
    state.currentAccount = null;
    state.orgs = [];
  },
  setAccounts(state, accounts) {
    state.accounts = accounts;
  },
};
const actions = {
  initSettings({ state, dispatch }) {
    state.currentAccount = settingsService.getCurrentAccount();
    if (state.orgs && state.orgs.length > 0) {
      if (state.currentAccount == null || state.currentAccount == "{}") {
        let currentAccountDefault = {
          isOrg: true,
          ...state.orgs[0],
        };
        settingsService.setCurrentAccount(currentAccountDefault);
        state.currentAccount = currentAccountDefault;
        dispatch("project/get", currentAccountDefault.uid, { root: true });
      }
    }
  },

  /**
   * set currently selected account
   * @param {Object} currentAccount
   */
  setCurrentAccount({ commit }, currentAccount) {
    settingsService.setCurrentAccount(currentAccount);
    commit("setCurrentAccount", currentAccount);
  },

  /**
   * set orgs on cache and update state
   * @param {Array} newOrgs
   */
  setOrgs({ commit }, newOrgs) {
    credentialService.setOrgs(newOrgs);
    commit("setOrgs", newOrgs);
  },
  /**
   * set user on org and cahce
   * @param {Object} newUser
   */
  setUser({ commit }, newUser) {
    const { preferences, ...data } = newUser;
    const { secret, phoneNumber, recoveryCodes, ...extraPreferences } = preferences;
    credentialService.setUser({
      ...data,
      preferences: extraPreferences,
    });
    commit("setUser", {
      ...data,
      preferences: extraPreferences,
    });
  },
  /**
   * persists a user session for subsequent auth
   * @param {*} param0
   * @param {*} accounts
   */
  initSession({ dispatch }, { user, currentAccount, orgs }) {
    dispatch("setUser", user);
    dispatch("setCurrentAccount", currentAccount);
    dispatch("setOrgs", orgs || []);
  },
};

export default function makeUser() {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
}
