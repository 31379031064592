<template>
  <div class="card flex justify-between h-40 bg-gray align-center px-3 py-1 my-1 rounded">
    <div class="flex justify-start">
      <div class="run-id">
        {{ externalId }}
      </div>
      <a
        text
        class="run-title"
        @click="$router.push({name: 'TestRunCaseEdit', params: { id: runId } })"
      >
        {{ runTitle }}
      </a>
    </div>
    <div
      v-if="runStatus"
      class="run-status fs-14px font-weight-medium"
      :class="{
        'light-blue--text': runStatus.toLowerCase().includes('active'),
        'amber--text text--darken-4': runStatus.toLowerCase().includes('incomplete'),
        'green--text': runStatus.toLowerCase().includes('passed'),
        'red--text': runStatus.toLowerCase().includes('failed'),
      }"
    >
      {{ runStatus }}
    </div>
  </div>
</template>
<script>
import { priorityColors } from '@/constants/colors.js';
export default {
  name: 'TestRunItem',
  props: {
    runId: [String, Number],
    runTitle: String,
    runStatus: String,
    externalId: String,
  },
  methods:{
    getPriorityColor(priority) {
      const foundPriority = priorityColors.find((color) => color.testCase === priority);
      return foundPriority?.value || 'unset';
    },
  }
}
</script>
<style>
.h-40{
  height: 40px;
}
.bg-gray{
  background-color: #F9FAFB;
}
.align-center{
  align-items: center;
}
.run-id {
  width: 70px;
  text-align: left;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
}

.run-title {
  color: #0C111D !important;
  font-size: 14px;
  font-weight: 400;
}

</style>
