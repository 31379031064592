<template>
  <div>
    <v-dialog
      v-model="isShowDialog"
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('plans.milestone.addTestPlanToMilestones', { planName: testPlan.name }) }}
            </h2>
            <v-btn
              icon
              @click="cancel"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="text-start pt-6">
            <div class="mb-1 text-start mt-4">
              {{ $t('plans.milestone.selectMilestones') }}
            </div>
            <div>
              <v-text-field 
                v-model="searchQuery" 
                dense 
                single-line 
                filled 
                color="blue" 
                prepend-inner-icon="mdi-magnify" 
                placeholder="Search"
                class="custom_input mx-0 rounded-lg"
              />
              <v-checkbox 
                v-for="(item) in filteredItems" 
                :key="item.uid"
                v-model="selectedMilestoneUids" 
                off-icon="mdi-checkbox-blank" 
                :value="item.uid" 
                dense 
                :label="item.name"
              />
            </div>
            <div class="d-flex justify-end mt-5">
              <v-btn
                width="10rem"
                color="gray-100"
                full-width
                class="mr-4 text-capitalize"
                elevation="0"
                @click="cancel"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                width="10rem"
                color="blue"
                dark
                full-width
                elevation="0"
                class="text-capitalize"
                @click="add"
              >
                {{ $t('add') }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers, mapMutations } from 'vuex';
import makeMilestonesService from '@/services/api/milestone';

const { mapActions } = createNamespacedHelpers('milestone');

let milestoneService;
export default {
  name: "PlanAddToMilestone",
  components: {},
  props: {
    showDialog: Boolean,
    testPlan: { type: Object, required: true },
    selectedPlans: { type: Array, required: true }
  },
  data() {
    return {
      milestones: [],
      selectedMilestoneUids: [],
      isShowDialog: true,
      searchQuery: '', 
    };
  },
  computed:{
    filteredItems() {
      return this.milestones.filter(milestone => 
        milestone.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  watch: {
    isShowDialog(newValue) {
      this.$emit('change-milestone-drawer-state', newValue)
    }
  },
  created() {
    const handle = this.$route.params.handle
    this.get({ handle: handle });
    milestoneService = makeMilestonesService(this.$api);
  },

  mounted() {
    this.getAllMileStones();
  },

  methods: {
    ...mapActions(['get', 'addToMilestone']),
    ...mapMutations('milestone', ['SET_MILESTONES']),
    add() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      let plansData = []

      if (this.testPlan){
        plansData = [{
          uid: this.testPlan.uid,
          name: this.testPlan.name,
          projectUid: this.testPlan.projectUid
        }]
      }

      if (this.selectedPlans.length > 0) {
        plansData = this.selectedPlans.map(({uid, name, projectUid})=> ({uid, name, projectUid}))
      }

      this.addToMilestone({swal: this.$swal, handle, projectKey, payload: {
        planUids: plansData.map(({uid})=> uid),
        milestoneUids: this.selectedMilestoneUids
        }})
    },
    cancel() {
      this.$emit('change-milestone-drawer-state', false)
    },
    async getAllMileStones(){
      try{
        const response = await milestoneService.getMilestones(
          this.$route.params.handle,
          this.$route.params.key
        );
        if(response.status === 200) {
          this.milestones = response.data.milestones;
          this.SET_MILESTONES(response.data.milestones);
        }
      } catch(err){
        console.log(err)
      }
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
