<template>
  <v-container
    class="pa-6 white align-start card app-height-global rounded-lg"
    fluid
  >
    <v-row
      v-if="!isBlankTable"
      justify="start"
      align="start"
    >
      <v-col
        cols="7"
        sm="7"
        class="search-bar-style"
      >
        <v-text-field
          v-model="searchTerm"
          class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
          :placeholder="$t('placeHolder.searchByColumn')"
          height="40"
          background-color="#F9F9FB"
        >
          <template v-slot:prepend-inner>
            <SearchIcon />
          </template>
        </v-text-field>
        <TestCasesFilter @filters="applyFilters" />
      </v-col>
      <v-col
        cols="5"
        sm="5"
        class="search-bar-style setting-btn-style"
      >
        <div class="btn-selector">
          <template>
            <div class="text-center">
              <SettingsMenu 
                table-type="case"
              />
            </div>
          </template>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        class="breadcrumb-container mt-4"
      >
        <v-breadcrumbs :items="breadCrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item v-if="isLastItem(item)">
              <b>{{ item.text }}</b>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item v-else>
              <span style="color: #667085">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col
        cols="12"
        sm="12"
      >
        <v-data-table
          v-model="selectedRows"
          :headers="filteredHeaders"
          :items="isSelectedData ? selectedData : displayTableData"
          item-key="uid"
          show-select
          class="table-fixed data-table-style"
          :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
          @input="handleSelectTestCases"
          @click:row="handleClick"
        >
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <div class="d-flex justify-center align-center">
              <v-checkbox
                id="remember-me-checkbox"
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                indeterminate-icon="icon-indeterminate"
                :input-value="props.value"
                :indeterminate="props.indeterminate"
                @change="on.input"
              />
            </div>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <div class="d-flex justify-center align-center">
              <v-checkbox
                id="remember-me-checkbox"
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                :input-value="isSelected"
                @change="select"
                @click.stop
              />
            </div>
          </template>

          <template v-slot:[`header.actions`]="{ header }">
            <div class="d-none">
              {{ header.text }}
            </div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <span class="custom-attribute font-weight-regular text-theme-table-text">{{ item.id }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-tooltip
              bottom
              left
              max-width="485px"
              :disabled="item.name.length < 61"
              content-class="tooltip-theme"
            >
              <template v-slot:activator="{ on, attrs }">
                <div 
                  class="custom-attribute text-truncate fw-semibold text-theme-table-text"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.name }}
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            <span :class="getColor(item.customFields.priority)">{{ item.customFields.priority }}</span>
          </template>
          <template v-slot:[`item.tags`]="{ item }">
            <TagEditor
              v-if="item.tags"
              :items="item.tags"
              @updateTag="updateTag($event, item)"
            />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row justify-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense
                  flat
                  class="text-left"
                >
                  <v-list-item @click="deleteTestCase(item.uid)">
                    <DeleteIcon />
                    <v-list-item-content class="ml-2 fs-14px text-theme-label">
                      {{ $t('delete') }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="handleRowEdit(item.testCaseRef)">
                    <EditIcon />
                    <v-list-item-content class="ml-2 fs-14px text-theme-label">
                      {{ $t('edit') }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="!isRepository"
        cols="12"
        sm="12"
        class="create-btn"
      >
        <v-text-field
          v-model="testName"
          class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
          :placeholder="$t('placeHolder.createQuickTestCase')"
          height="38"
          background-color="#F9F9FB"
          single-line
        >
          <template v-slot:prepend-inner>
            <iconAttach class="mt-1" />
          </template>
        </v-text-field>
        <v-select
          v-model="testTemplate"
          height="38px"
          :items="testTemplates"
          item-text="name"
          dense
          item-value="uid"
          class="text-field bottom-input-style rounded-lg field-theme custom-prepend"
          placeholder="Choose the template"
          append-icon="mdi-chevron-down"
          background-color="#F9F9FB"
        />
        <v-btn
          color="blue"
          class="text-capitalize mt-0"
          :class="{ 'disabled-action': !testName || !testTemplate }"
          depressed
          height="40"
          @click="quickCreate"
        >
          {{ $t('create') }}
        </v-btn>
      </v-col>
      <v-col
        v-if="!isRepository"
        cols="12"
        sm="12"
        class="breadcrumb-container mt-4"
      />
      <v-col
        v-if="!isRepository"
        cols="12"
        sm="12"
        class="round-8 action-btn-wrapper px-6 py-4"
      >
        <v-btn
          v-if="allowAction && actionBtnShow"
          width="141px"
          color="#F2F4F7"
          full-width
          height="40"
          depressed
          class="text-capitalize btn-theme mr-3"
          elevation="0"
          @click="onCancelAction"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-menu
          v-if="allowAction && actionBtnShow"
          v-model="menuOpen"
          offset-y
          top
          right
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="text-capitalize btn-theme"
              height="40"
              width="141px"
              v-bind="attrs"
              :depressed="true"
              v-on="on"
            >
              {{ $t('actions') }}
              <v-icon size="20">
                {{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </template>
          <v-list class="actions-list font-inter text-left">
            <v-list-item
              :key="1"
              class="action-btn actions-item"
              @click="handleEditClick"
            >
              <div class="d-flex align-center">
                <EditIcon />
              </div>
              <v-list-item-title class="pl-3">
                {{ $t('edit') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="2"
              class="action-btn actions-item"
              @click="handleExportClick"
            >
              <div class="d-flex align-center">
                <ExportIcon />
              </div>
              <v-list-item-title class="pl-3">
                {{ $t('export') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="3"
              class="action-btn actions-item"
              @click="handleBulkRemove"
            >
              <div class="d-flex align-center">
                <DeleteIcon />
              </div>
              <v-list-item-title class="pl-3 error--text">
                {{ $t('remove') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="h-100"
      justify="start"
      align="start"
    >
      <v-col
        cols="12"
        sm="12"
        class="h-100 flex"
      >
        <div class="block">
          <img
            :src="require('@/assets/png/blank-case-background.png')"
            style="margin-left: auto; margin-right: auto; display: block"
          >
          <h2 class="blank-title">
            {{ $t('manageTestCaseEasily') }}
          </h2>
          <p class="blank-description">
            {{ $t('startCreateTestCase') }}
          </p>
          <div class="w-100 flex">
            <v-btn
              color="#0C2FF3"
              dark
              class="text-capitalize px-6"
              depressed
              :to="{ name: 'CreateTestCases' }"
            >
              {{ $t('createTestCase') }}
              <v-icon
                class="ml-1"
                size="xs"
              >
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <EditDialog
      :is-open="isOpenEditDialog"
      :priorities="priorities"
      :tags="tags"
      @closeDialog="handleCloseEditDialog"
      @clickSave="updateSelectedCases"
    />
    <ExportDialog
      :is-open="isOpenExportDialog"
      :selected-rows="selectedRows"
      @closeDialog="handleCloseExportDialog"
    />
    <ConfirmDialog
      :is-open="isOpenConfirmDialog"
      @closeDialog="handleCloseConfirmDialog"
      @confirm="handleConfirm"
    />
    <ConfirmBulkDeleteDialog
      :title="$t('testruns.test_case.bulk_remove.title', { count: selectedRows.length })"
      :is-open="isOpenConfirmBulkDeleteDialog"
      @closeDialog="handleCloseConfirmBulkDeleteDialog"
      @confirm="confirmBulkRemove"
    />
  </v-container>
</template>

<script>
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import TestCasesFilter from '@/views/Tests/Case/Components/Filter.vue';
import EditDialog from '@/views/Tests/Case/Components/EditDialog.vue';
import ExportDialog from '@/views/Tests/Case/Components/ExportDialog.vue';
import ConfirmDialog from '@/views/Tests/Case/Components/ConfirmDialog.vue';
import ConfirmBulkDeleteDialog from '@/views/Tests/Case/Components/ConfirmBulkDeleteDialog.vue';
import iconAttach from '@/assets/svg/outline-file-attach16x16-blue.svg';
import makeCasesService from '@/services/api/case';
import makeTemplateService from '@/services/api/template';
import { showErrorToast } from '@/utils/toast';
import { createNamespacedHelpers, mapGetters, mapActions } from 'vuex';
import SearchIcon from '@/assets/svg/search-icon.svg';
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ExportIcon from '@/assets/svg/export.svg';
import TagEditor from '@/components/base/TagEditor.vue';

const { mapState } = createNamespacedHelpers('user');

let caseService;
let templateService;

export default {
  components: {
    TestCasesFilter,
    EditDialog,
    ExportDialog,
    ConfirmDialog,
    iconAttach,
    SettingsMenu,
    ConfirmBulkDeleteDialog,
    SearchIcon,
    DeleteIcon,
    EditIcon,
    ExportIcon,
    TagEditor,
  },
  props: {
    isRepository: Boolean,
    caseItems: Array,
    initialSelected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    breadCrumbs: [],
    allowAction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // test state for blank page
      isBlankTable: false,
      isOpenEditDialog: false,
      isOpenExportDialog: false,
      isOpenConfirmDialog: false,
      headers: [],
      isOpenConfirmBulkDeleteDialog: false,
      isSelectedData: false,
      isAddedTable: false,
      openFilterDrawer: true,
      toggleSelect: false,
      testName: '',
      testTemplate: '',
      tableSelectedCases: [],
      menuOpen: false,
      searchTerm: '',
      filters: [],
      actionBtnShow: false,
      selectedData: [],
      states: [{ text: 'High' }, { text: 'Medium' }, { text: 'Low' }],
      priorities: [
        { value: 'High', text: 'High' },
        { value: 'Medium', text: 'Medium' },
        { value: 'Low', text: 'Low' },
      ],
      tags: [
        { value: 'test', text: '#test' },
        { value: 'billing', text: '#billing' },
        { value: 'performance', text: '#performance' },
        { value: 'navigation', text: '#navigation' },
      ],
      testTemplates: [],
      localCaseItems: [],
    };
  },
  
  computed: {
    ...mapState(['currentAccount']),
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    selectedRows: {
      get() {
        return this.tableSelectedCases.length ? this.tableSelectedCases : this.initialSelected;
      },
      set(value) {
        this.tableSelectedCases = value;
      },
    },
    selectedItemsTitles() {
      return this.selectedItems.map((i) => i.text).join(', ');
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders() {
      const filtered = this.filteredMenuHeaders.filter((header) => header.checked);
      return filtered;
    },
    displayTableData() {
      return this.localCaseItems.filter((item) => {
        if (!item.active) return false;
        if (!item.name.toLowerCase().includes(this.searchTerm.toLowerCase())) return false;
        if (this.filters.priorities?.length && !this.filters.priorities.includes(item.customFields?.priority))
          return false;
        if (this.filters.tags?.length) {
          let tagExists = false;
          for (const tag of item.customFields.tags) if (this.filters.tags.includes(tag)) tagExists = true;

          return tagExists;
        }
        if (this.filters.tags?.length && !this.filters.tags.includes(item.customFields?.tags)) return false;
        return item;
      });
    },
  },

  watch: {
    caseItems(newVal) {
      this.localCaseItems = newVal;
    },
  },

  created() {
    if(!this.dynamicHeaders.case) {
      this.initializeHeaders({ type: 'case' });
    }
    this.headers = this.dynamicHeaders.case;
    caseService = makeCasesService(this.$api);
    templateService = makeTemplateService(this.$api);
  },

  async mounted() {
    const response = await templateService.getTemplates(
      this.$route.params.handle,
      this.$route.params.key,
      'per_page=9999'
    );
    this.testTemplates = response.data.templates;
    this.getCaseHistoryData();
  },

  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    // header accions
    handleCloseConfirmBulkDeleteDialog() {
      this.isOpenConfirmBulkDeleteDialog = false;
    },
    handleCloseConfirmDialog() {
      this.isOpenConfirmDialog = false;
    },
    handleConfirm() {
      this.isOpenConfirmDialog = false;
    },
    handleEditClick() {
      this.isOpenEditDialog = true;
    },
    handleCloseEditDialog() {
      this.isOpenEditDialog = false;
    },
    handleExportClick() {
      this.isOpenExportDialog = true;
    },
    handleCloseExportDialog() {
      this.isOpenExportDialog = false;
    },
    isLastItem(item) {
      return item.text == this.breadCrumbs[this.breadCrumbs.length - 1].text;
    },
    getColor(priority) {
      if (priority == 'High') {
        return 'text-red';
      } else if (priority == 'Medium') {
        return 'text-yellow';
      } else {
        return 'text-green';
      }
    },
    onCancelAction() {
      this.selectedRows = [];
    },
    applyFilters(filters) {
      this.filters = filters;
    },
    handleSelectTestCases() {
      if (this.selectedRows.length > 0) {
        this.actionBtnShow = true;
      } else {
        this.actionBtnShow = false;
      }
      this.$emit('selectedCases', this.selectedRows);
    },
    handleClick(row) {
      this.$emit('expandDetail', row);
    },
    addSelectedCases() {
      this.selectedData = this.selectedRows;
      this.isSelectedData = true;
      this.isAddedTable = true;
    },
    removeSelectedCases() {
      this.isAddedTable = false;
    },
    // quick create test case function
    async quickCreate() {
      const selectedTemplate = this.testTemplates.find((template) => template.uid == this.testTemplate);
      const payload = {
        name: this.testName,
        externalId: '',
        source: 'testfiesta',
        parentId: this.$store.state.folder.selectedFolderUID || null,
        templateId: this.testTemplate,
        customFields: {
          tags: [],
          priority: this.priorities[this.priorities.length - 1].value,
          templateFields: selectedTemplate ? selectedTemplate.customFields : null,
        },
      };
      this.$emit('createCase', payload);
      this.testName = '';
      this.testTemplate = '';
    },
    // get Test case history function
    async getCaseHistoryData() {
      let handle = this.currentAccount.handle;
      let folderId = this.$store.state.folder.selectedFolderUID; // Example: getting folder ID from Vuex store
      this.tableData = [];
      try {
        const res = await caseService.getFolderCases(handle, this.$route.params.key, folderId, 10, 0);
        if (res.status == 200) {
          const cases = res.data.cases;
          this.localCaseItems = cases;
        } else {
          console.error('Error occured');
        }
      } catch (err) {
        console.error('Error:', err);
      }
    },
    // delete Test case from uid
    async deleteTestCase(uid) {
      this.$parent.$emit('caseRemove', uid);
    },
    async handleBulkRemove() {
      if (this.selectedRows.length > 0) {
        this.isOpenConfirmBulkDeleteDialog = true;
      }
    },

    async confirmBulkRemove() {
      const itemUIDs = this.selectedRows.map((row) => row.uid);
      const payload = {
        ids: itemUIDs,
      };
      this.confirmDialogOpen = false;
      this.$parent.$emit('bulkRemove', payload);
      this.isOpenConfirmBulkDeleteDialog = false;
    },

    async updateSelectedCases(selectedFolder, selectedPriority, tagData) {
      try {
        const payload = this.selectedRows.map((row) => {
          return {
            ...row, 
            parentId: selectedFolder || row.parentUid || null,
            customFields: {
              ...row.customFields,
              priority: selectedPriority,
            },
            tagIds: tagData.selectedTags.map(tag => tag.uid),
            tagReplacements: [
              {
                existingTagIds: tagData.replaceTag.map(tag => tag.uid),
                newTagIds: tagData.withTag.map(tag => tag.uid)
              }
            ]
          };
        });

        const response = await caseService.updateBulkTestCases(this.$route.params.handle, this.$route.params.key, {
          cases: payload,
        });

        if (response.status === 207) {
            showErrorToast(this.$swal, 'partialError');
        } 
        else if (response.status !== 200) {
          showErrorToast(this.$swal, 'updateError', {item: 'test cases'});
        }
        else {
          // Refresh data after successful update
          await this.getCaseHistoryData();
          this.isOpenEditDialog = false;
          this.selectedRows = [];
        }
      } catch (err) {
        showErrorToast(this.$swal, 'genericError', {message: err});
      }
    },
    async updateTag(data, itemData){
        const {tagIds, tagReplacements} = this.tagFomation(itemData.tags.map(tag => tag.uid), data.map(tag => tag.uid))
      const response = await caseService.updateTestCase(
        this.$route.params.handle,
        this.$route.params.key,
        itemData.testCaseRef,
        {
          tagIds: tagIds,
          tagReplacements: tagReplacements
        }
      );
      

        await this.getCaseHistoryData();
    },

    tagFomation(originalTags, newTags) {
      const tagIdsToRemove = originalTags.filter(tagId => !newTags.includes(tagId));
      const tagIdsToAdd = newTags.filter(tagId => !originalTags.includes(tagId));

      const tagReplacements = [];

      if (tagIdsToRemove.length > 0) {
        tagReplacements.push({
          existingTagIds: tagIdsToRemove,
          newTagIds: []
        });
      }

      if (tagIdsToAdd.length > 0) {
        tagReplacements.push({
          existingTagIds: [],
          newTagIds: tagIdsToAdd
        });
      }

      return {
        tagIds: tagIdsToAdd,
        tagReplacements
      };
    },
    handleRowEdit(uid)
    {
      this.$router.push({ name: 'EditTestCases', params: { uid: uid } });
      // TODO - pass this item via state to avoid extra API calls
      //let caseItem;
      //for (let item in this.caseItems) {
      //  if (this.caseItems[item].uid == uid) {
      //    caseItem = this.caseItems[item]
      //  }
      //}
      //this.$router.push({ name: 'EditTestCases', params: { uid: caseItem } })
    },
  },
};
</script>

<style scoped>
.color-red {
  color: #f2284e !important;
}

.f-color-red {
  color: #f2284e !important;
}

.round-8 {
  border-radius: 8px;
}

.round-6 {
  border-radius: 6px;
}

.h-40 {
  height: 40px !important;
}

.btn-selector {
  position: relative;
}

.modal-main-area {
  height: 100%;
  padding: 32px 32px 32px 32px;
}

.dialog-title {
  font-weight: 900 !important;
}

.filter-dialog {
  padding-top: 15px;
}

.dialog-action {
  width: 90%;
  display: flex;
  position: absolute;
  bottom: 25px;
}

.btn-selector .selector-wrapper {
  position: relative;
}

.selector-style {
  position: absolute;
  right: 0;
  left: unset;
  top: 30px;
  min-width: 240px;
}

.modal-btn {
  width: 45%;
}

.f-color-white {
  color: white !important;
}

.text-red {
  color: #ef5350;
}

.text-green {
  color: #66bb6a;
}

.text-yellow {
  color: #ffa726;
}

.align-start {
  align-items: baseline !important;
  font-family: Inter !important;
}

.search-box-style {
  padding-top: 0;
  border-radius: 8px;
}

.search-bar-style {
  display: flex;
  padding-bottom: 0;
  justify-content: space-between;
}

.setting-btn-style {
  display: flex;
  justify-content: flex-end;
}

.setting-btn {
  position: absolute;
  right: 10px;
  width: 40px !important;
  min-width: 40px !important;
}

.breadcrumb-container {
  padding: 0;
}

.breadcrumb-container ul {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
}

.create-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}

.create-btn button {
  color: white !important;
  margin-top: 10px;
}

.bottom-input-style {
  margin-top: 0;
  border-radius: 5px;
  margin-right: 10px;
}

.bottom-input-style .v-text-field__slot {
  padding-left: 10px;
}

.bottom-input-style .v-select__selections {
  padding-left: 10px;
}

.data-table-style {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.data-table-style tbody tr {
  height: 50px !important;
  min-height: 50px;
  cursor: pointer;
}

.v-input__prepend-inner {
  padding-left: 10px;
}

.v-list-item__content {
  text-align: start;
}

.v-breadcrumbs__item .normal-font-color {
  color: rgb(93, 101, 121) !important;
  color: red;
}

.search-field .v-input__slot {
  display: flex;
  align-items: center !important;
}

.search-field .v-input__prepend-inner {
  align-self: center;
  margin-top: 0 !important;
  padding-left: 0px;
  padding-right: 8px !important;
}

.text-field .v-input__slot {
  background-color: #f9f9fb !important;
}

.btn-restore {
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
}

.menu-header {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.absolute {
  position: absolute;
}

.bottom-right {
  display: flex;
  justify-content: right;
  right: 24px;
  bottom: 16px;
}

.f-color-blue {
  color: #0c2ff3;
}

.action-btn .v-list-item__title {
  display: flex;
  justify-content: flex-start;
}

.h-100 {
  height: 100%;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  display: block;
}

h2.blank-title {
  text-align: center;
}

p.blank-description {
  max-width: 500px;
  text-align: center;
}

.none {
  display: none;
}

.custom-attribute {
  white-space: nowrap;
}
.v-data-table .v-data-table__wrapper tbody tr {
  height: auto !important;
}

.v-data-table .v-data-table__wrapper tbody tr td {
  height: auto !important;
  padding-top: 0;
}
.action-btn-wrapper {
  position: sticky;
  bottom: 0;
  background-color: white;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  z-index: 8;
}
</style>


