var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"white fill-height align-start card workspace_list",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"search-bar-style",attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{staticClass:"text-field mr-3 round-8",attrs:{"placeholder":_vm.$t('placeHolder.searchByNameId'),"dense":"","filled":"","single-line":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('iconSearch',{staticClass:"mr-2"})]},proxy:true}]),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('TestCasesFilter',{on:{"filters":_vm.applyFilters}})],1),_c('v-col',{staticClass:"search-bar-style setting-btn-style",attrs:{"cols":"8"}},[_c('div',{staticClass:"btn-selector"},[[_c('div',{staticClass:"text-center"},[_c('SettingsMenu',{attrs:{"table-type":"workspace"}})],1)]],2)]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"data-table-style",attrs:{"headers":_vm.filteredHeaders,"items":_vm.isSelectedData ? _vm.selectedData : _vm.displayTableData,"item-key":"uid","show-select":"","header-props":{ 'sort-icon': 'mdi-chevron-down' }},on:{"input":_vm.updateSelectedRows,"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:`header.actions`,fn:function({ header }){return [_c('div',{staticClass:"d-none"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:`item.id`,fn:function({ item }){return [_c('span',{staticClass:"custom-attribute"},[_vm._v(_vm._s(item.externalId))])]}},{key:`item.assignDate`,fn:function({ item }){return [_c('span',{staticClass:"custom-attribute text-center"},[_vm._v(_vm._s(item.lastAssignedAt ?? '-'))])]}},{key:`item.status`,fn:function({ item }){return [_c('span',{class:{
              'light-blue--text': item.status.toLowerCase().includes('new'),
              'amber--text text--darken-4': item.status.toLowerCase().includes('incomplete'),
              'green--text': item.status.toLowerCase().includes('passed'),
              'red--text': item.status.toLowerCase().includes('failed'),
            }},[_vm._v(_vm._s(item.status))])]}},{key:`item.priority`,fn:function({ item }){return [(item.priority)?_c('span',{class:{
              'amber--text text--darken-4': item.priority.toLowerCase().includes('medium'),
              'green--text': item.priority.toLowerCase().includes('low'),
              'red--text': item.priority.toLowerCase().includes('high'),
            }},[_vm._v(_vm._s(item.priority))]):_vm._e()]}},{key:`item.name`,fn:function({ item }){return [_c('span',{staticClass:"custom-attribute"},[_vm._v(_vm._s(item.name))])]}},{key:`item.project`,fn:function({ item }){return [_c('span',{staticClass:"custom-attribute"},[_vm._v(_vm._s(item.projectName))])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-left",attrs:{"dense":""}},[(false)?_c('v-list-item',[_c('v-list-item-icon',[_c('pinata-icon')],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('runWithPinata')))])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('selectedExecutions', [item])}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"#344054"}},[_vm._v(" mdi-plus ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('addResult')))])],1)],1)],1)],1)]}},{key:`item.tags`,fn:function({item}){return [(item.caseFields.tags?.length)?_c('div',{staticClass:"tags"},_vm._l((item.caseFields.tags),function(item,index){return _c('v-chip',{key:index,staticClass:"light-blue white--text",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(item)+" ")])}),1):_vm._e()]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }